import { Checkbox, Grid, Header } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import DynamoQuerySearchControls from '../../components/SearchComponents/DynamoQuerySearchControls'
import { DynamoQueryFilter } from '../../actions/Utils'
import { useFilterService } from '../../services/DynamoQueryFilterService'
import { useSearchFilterStore } from '../../context/SearchFilterStoreContext'
import { useRouterProps } from '../../router/RouterProps'
import { NewTeamsList } from './component/NewTeamsList'
import { useTeamsService } from './services/TeamsService'
import CreateTeamForm from './component/CreateTeam/CreateTeamForm'

const searchableParameters = ['primaryName', 'description', 'tenantId']

export const createAdditionalFilter = (customerOnly: boolean, prodOnly: boolean): DynamoQueryFilter => {
  let filter = ''
  if (customerOnly) {
    // eslint-disable-next-line no-template-curly-in-string
    filter += '${owner} = {Customer}'
  }
  if (customerOnly && prodOnly) {
    filter += ' AND '
  }
  if (prodOnly) {
    // eslint-disable-next-line no-template-curly-in-string
    filter += '${environment} = {Production}'
  }
  return filter ? { filter } : {}
}

const title = 'Teams'

export const NewTeams = () => {
  const props = useRouterProps()
  const { teamsSearchFilter, setTeamsSearchFilter, updateSearchParams } = useSearchFilterStore()
  // eslint-disable-next-line no-template-curly-in-string
  const additionalFilter = createAdditionalFilter(
    teamsSearchFilter.showCustomerTeamsOnly.value,
    teamsSearchFilter.showProductionTeamsOnly.value
  )
  const { searchValue, searchOptions, handleSearchCategoryChange, handleSearchChange, dynamoQueryFilter } =
    useFilterService(searchableParameters, 'primaryName', additionalFilter)
  const teamsService = useTeamsService(dynamoQueryFilter)
  const { isFetching, refetch } = teamsService
  const debouncedSearchValues = useDebounce(searchValue, 500)
  const { setDocumentTitle } = useDocumentTitle(title)
  const [toggleValues, setToggleValues] = useState(teamsSearchFilter)

  const onToggleFilter = (filter: 'showCustomerTeamsOnly' | 'showProductionTeamsOnly') => () => {
    setToggleValues({
      ...teamsSearchFilter,
      [filter]: {
        ...teamsSearchFilter[filter],
        value: !teamsSearchFilter[filter].value
      }
    })
  }

  useEffect(() => {
    setTeamsSearchFilter({ ...teamsSearchFilter, ...toggleValues })
    updateSearchParams(props, toggleValues, ['showCustomerTeamsOnly', 'showProductionTeamsOnly'])
  }, [toggleValues])

  useEffect(() => {
    updateSearchParams(props, toggleValues, ['showCustomerTeamsOnly', 'showProductionTeamsOnly'], true)
  }, [props.location.search])

  /**
   * Check search filter store on mount and set search value and category.
   */
  useEffect(() => {
    if (teamsSearchFilter.searchValue) {
      handleSearchChange({}, { value: teamsSearchFilter.searchValue })
    }
    if (teamsSearchFilter.searchCategory) {
      handleSearchCategoryChange({}, { value: teamsSearchFilter.searchCategory })
    }
    setDocumentTitle(title)
  }, [])

  /**
   * Update search filter store for teams.
   */
  useEffect(() => {
    const value = debouncedSearchValues.searchValue
    const category = debouncedSearchValues.searchCategory
    setTeamsSearchFilter({ ...teamsSearchFilter, searchValue: value, searchCategory: category })
  }, [debouncedSearchValues])

  return (
    <div className="route-component paginated-table">
      <Header as="h2">Teams</Header>
      <Grid stackable={true} columns={3} verticalAlign="middle">
        <DynamoQuerySearchControls
          isFetching={isFetching}
          refetch={refetch}
          searchValue={searchValue}
          searchOptions={searchOptions}
          handleSearchChange={handleSearchChange}
          handleSearchCategoryChange={handleSearchCategoryChange}
        />
        <Grid.Column>
          <Checkbox
            toggle
            checked={teamsSearchFilter.showCustomerTeamsOnly.value}
            onClick={onToggleFilter('showCustomerTeamsOnly')}
            label={<label htmlFor="showCustomerTeamsOnly">Show customer teams only</label>}
            id="showCustomerTeamsOnly"
          />
          <Checkbox
            toggle
            checked={teamsSearchFilter.showProductionTeamsOnly.value}
            onClick={onToggleFilter('showProductionTeamsOnly')}
            label={<label htmlFor="showProductionTeamsOnly">Show production teams only</label>}
            id="showProductionTeamsOnly"
          />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <CreateTeamForm buttonText="Create Team" title="Create a new team" />
        </Grid.Column>
      </Grid>
      <NewTeamsList teamsService={teamsService} showCustomerId={true} />
    </div>
  )
}
