import * as React from 'react'
import { Button, List, Popup, Table, TableHeaderCell } from 'semantic-ui-react'
import dayjs from 'dayjs'
import { Link, useSearchParams } from 'react-router-dom'
import { Delivery } from '../../../actions/Deliveries'
import { sortData } from '../../tableComponents/TableFunctions'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { FilterCategories, filtersMatchAny, searchParamsToFilters } from '../../filterComponents/Filters'

const versionFilter = /([0-9]*)\.([0-9]*)\.([0-9])\.([0-9])*/

interface IProps {
  deliveries?: Delivery[]
  loading: boolean
  filterCategories: FilterCategories
  displayLocalTime?: boolean
  simple?: boolean
}

const DeliveryListStatusIcon = (props: { status: string }) => {
  switch (props.status) {
    case 'pending':
      return (
        <Popup
          position="right center"
          content="Pending"
          trigger={<List.Icon name="clock" style={{ color: '#008cff' }} size="large" />}
        />
      )
    case 'approved':
      return (
        <Popup
          position="right center"
          trigger={<List.Icon name="check circle" color="green" size="large" />}
          content="Approved"
        />
      )
    case 'rejected':
      return (
        <Popup
          position="right center"
          trigger={<List.Icon name="times circle" color="red" size="large" />}
          content="Rejected"
        />
      )
    case 'superseded':
      return (
        <Popup
          position="right center"
          trigger={<List.Icon name="minus circle" color="yellow" size="large" />}
          content="Superseded"
        />
      )
    default:
      return (
        <Popup position="right center" trigger={<List.Icon name="question circle" size="large" />} content={status} />
      )
  }
}

export const DeliveriesList = (props: IProps) => {
  const [sortColumn, setSortColumn] = React.useState<keyof Delivery>('createdAt')
  const [sortDirection, setSortDirection] = React.useState<'ascending' | 'descending'>('descending')
  const { loading, deliveries, filterCategories } = props
  const [searchParams] = useSearchParams()

  const handleSort = (clickedColumn: string) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn as keyof Delivery)
      setSortDirection('ascending')
    } else {
      setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
    }
  }

  const compareVersion = (a: Delivery, b: Delivery) => {
    const matchA = versionFilter.exec(a.version)
    const matchB = versionFilter.exec(b.version)
    if (matchA === null || matchB === null) {
      return 0
    }
    const matchesA = matchA.slice(1).map(x => parseInt(x, 10))
    const matchesB = matchB.slice(1).map(x => parseInt(x, 10))
    return (
      matchesA[0] - matchesB[0] || matchesA[1] - matchesB[1] || matchesA[2] - matchesB[2] || matchesA[3] - matchesB[3]
    )
  }

  const sortDeliveries = (data: Delivery[]) => {
    if (data.length > 0 && sortColumn === 'version') {
      data.sort(compareVersion)
      if (sortDirection === 'descending') {
        data.reverse()
      }
      return data
    }
    return sortData(data, sortColumn, sortDirection)
  }

  const SortedDeliveriesHeaderCell = SortedHeaderCell(sortColumn, sortDirection, handleSort)
  const filterDeliveries = (): Delivery[] => {
    if (!deliveries || deliveries.length === 0) {
      return []
    }
    const filters = searchParamsToFilters(searchParams, filterCategories)
    let result = deliveries
    const groups = Object.groupBy(filters, filter => filter.category)
    for (const [category, categoryFilters] of Object.entries(groups)) {
      result = result.filter(delivery =>
        filtersMatchAny(categoryFilters!, delivery[category as keyof Delivery] as string, false)
      )
    }
    return result
  }
  const filteredDeliveries = filterDeliveries()
  const sortedDeliveries: Delivery[] = sortDeliveries(filteredDeliveries)
  return (
    <Table striped color="blue" size="small" stackable sortable className={!props.simple ? 'sticky-table' : undefined}>
      <Table.Header>
        <Table.Row>
          <SortedDeliveriesHeaderCell title="Created At" name="createdAt" />
          <SortedDeliveriesHeaderCell title="Version" />
          <SortedDeliveriesHeaderCell title="Application" name="applicationName" />
          <SortedDeliveriesHeaderCell textAlign="center" title="Status" name="status" />
          <TableHeaderCell textAlign="center">Tag</TableHeaderCell>
          <TableHeaderCell textAlign="center">Details</TableHeaderCell>
        </Table.Row>
      </Table.Header>
      <TableLoaderWrap loading={loading} array={deliveries || []} emptyMessage="No Deliveries Found">
        <Table.Body>
          {sortedDeliveries.map(delivery => (
            <Table.Row key={delivery.id}>
              <Table.Cell className="table-cell-overflow">
                {(props.displayLocalTime &&
                  delivery.createdAt &&
                  dayjs(delivery.createdAt).format('MMMM D YYYY, h:mm:ss a')) ||
                  delivery.createdAt}
              </Table.Cell>
              <Table.Cell>{delivery.version}</Table.Cell>
              <Table.Cell>{delivery.applicationName}</Table.Cell>
              <Table.Cell textAlign="center">
                <DeliveryListStatusIcon status={delivery.status} />
              </Table.Cell>
              <Table.Cell>{delivery.tag}</Table.Cell>
              <Table.Cell textAlign="center">
                <Link to={`/applications/deliveries/${delivery.applicationName}/${delivery.version}`}>
                  <Button icon="file alternate outline" className="square-icon-button" aria-label="details" />
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </TableLoaderWrap>
    </Table>
  )
}
