import React from 'react'
import { Button } from 'semantic-ui-react'
import { createErrorToast } from '../../alertComponents/Alert'
import { IJiraTicket, IReleaseNoteInfo } from '../../../actions/ReleaseNotes'

interface IProps {
  release?: IReleaseNoteInfo
  ticket: IJiraTicket
  addTicketToFixVersionHook: (ticket: IJiraTicket) => Promise<void>
}

export const AddTicketToReleaseButton = (props: IProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const handleAddTicketToFixVersion = () => {
    setIsLoading(true)
    props.addTicketToFixVersionHook(props.ticket).then(
      () => setIsLoading(false),
      error => createErrorToast(error)
    )
  }

  return (
    <Button
      loading={isLoading}
      color="green"
      icon="plus"
      onClick={handleAddTicketToFixVersion}
      className="square-icon-button"
      target="_blank"
    />
  )
}
