import { compact, flatten, groupBy, chunk } from 'lodash'
import { Button, Checkbox, Grid, Header } from 'semantic-ui-react'
import { useEffect, useMemo, useState } from 'react'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { getUserSummary, IUserSummary, TenantWithNameAndRegion } from '../../actions/Tenants'
import { useGetTenantsInfoQuery } from '../../queries/GetTenantsInfoQuery'
import { useGetUserSummaryOverviewQuery } from '../../queries/GetUserSummaryOverviewQuery'
import { UserSummaryTable } from './components/UserSummaryTable'

export interface TenantWithSummary {
  tenant: TenantWithNameAndRegion
  summary: IUserSummary
}

export const combineTenantWithSummary =
  (summaries: IUserSummary[]) =>
  (tenant: TenantWithNameAndRegion): TenantWithSummary | undefined => {
    const summary = summaries.find(summ => summ.tenantId === tenant.tenantId)
    if (!summary) {
      return {
        tenant,
        summary: {
          tenantId: tenant.tenantId,
          totalActiveResources: null,
          totalActiveSchedulers: null
        }
      }
    }
    return {
      tenant,
      summary
    }
  }

export const getSummaryForTenants = async (tenants: TenantWithNameAndRegion[]): Promise<TenantWithSummary[]> => {
  const tenantsByRegion = groupBy<TenantWithNameAndRegion>(tenants, 'apiUrl')
  const featureRequests = Object.keys(tenantsByRegion).map(async (apiUrl): Promise<TenantWithSummary[]> => {
    const regionTenants = tenantsByRegion[apiUrl]
    const tenantsChunked = chunk(regionTenants, 100).map(async regionTenantsChunk => {
      const tenantSummary = await getUserSummary(
        regionTenantsChunk.map(tenant => tenant.tenantId),
        apiUrl
      )
      const tenantsWithSummary = regionTenantsChunk.map(combineTenantWithSummary(tenantSummary))
      return compact(tenantsWithSummary)
    })
    const tenantsAndSummaryByChunk = await Promise.all(tenantsChunked)
    return flatten(tenantsAndSummaryByChunk)
  })
  const tenantsAndSummaryByRegion = await Promise.all(featureRequests)
  return flatten(tenantsAndSummaryByRegion)
}

const title = 'Users Report'

export const UserSummaryOverview = () => {
  const [showCustomerOnly, setShowCustomerOnly] = useState<boolean>(true)
  const [showProductionOnly, setShowProductionOnly] = useState<boolean>(true)
  const { data: tenantsData, refetch } = useGetTenantsInfoQuery()
  const { data, isFetching } = useGetUserSummaryOverviewQuery(tenantsData || [])
  const { setDocumentTitle } = useDocumentTitle(title)

  useEffect(() => {
    setDocumentTitle(title)
  }, [])

  const filteredTenants = useMemo(
    () =>
      (data || [])
        .filter(tenant => !showCustomerOnly || tenant.tenant.category === 'Customer')
        .filter(tenant => !showProductionOnly || tenant.tenant.environment === 'Production')
        .sort((a, b) => a.tenant.teamName.localeCompare(b.tenant.teamName)),
    [data, showCustomerOnly, showProductionOnly]
  )

  return (
    <div className="route-component legacy-page">
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column width={4}>
          <Header as="h3">{'Users report'}</Header>
        </Grid.Column>
        <Grid.Column width={9} textAlign="right">
          <Checkbox
            toggle
            checked={showCustomerOnly}
            onClick={() => setShowCustomerOnly(prevStateCust => !prevStateCust)}
            label="Show customer teams only"
            style={{ padding: '10px' }}
          />
          <Checkbox
            toggle
            checked={showProductionOnly}
            onClick={() => setShowProductionOnly(prevStateProd => !prevStateProd)}
            label="Show production teams only"
            style={{ padding: '10px' }}
          />
        </Grid.Column>
        <Grid.Column textAlign="right" width={3}>
          <Button size="large" color="blue" content="Fetch data" onClick={() => refetch()} loading={isFetching} />
        </Grid.Column>
      </Grid>
      <UserSummaryTable data={filteredTenants} loading={isFetching} />
    </div>
  )
}
