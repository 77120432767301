import { useQuery } from '@tanstack/react-query'
import { expensiveQueryConfig } from '../views/Utils'
import { TenantWithNameAndRegion } from '../actions/Tenants'
import { getSummaryForTenants, TenantWithSummary } from '../views/tenants/UserSummaryOverview'

export const useGetUserSummaryOverviewQuery = (tenantsData: TenantWithNameAndRegion[]) =>
  useQuery<TenantWithSummary[]>(
    ['tenants', 'summary', tenantsData],
    () => (tenantsData ? getSummaryForTenants(tenantsData) : []),
    {
      enabled: !!tenantsData,
      ...expensiveQueryConfig
    }
  )
