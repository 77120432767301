import { useQuery } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { getReleaseNoteDetails, getReleaseNotes } from '../../actions/ReleaseNotes'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { getReleaseNotesPage } from '../../actions/Confluence'

export const useGetReleaseNotesQuery = () =>
  useQuery(['releaseNotes'], () => getReleaseNotes(), {
    onError: error => createErrorToast(error)
  })

export const useGetReleaseNoteQuery = (componentName?: string, version?: string) =>
  useQuery(['releaseNote', componentName, version], () => getReleaseNoteDetails(componentName!, version!), {
    onError: error =>
      createErrorToast(`No release notes found for component ${componentName} with version ${version}.`),
    enabled: !!componentName && !!version
  })

export const useGetReleaseNotesPageQuery = (component?: string, version?: string) =>
  useQuery(['releaseNotesPage', component, version], () => getReleaseNotesPage(component!, version!), {
    onError: error => {
      if (isAxiosError(error) && error.response && error.response.status !== 404) {
        throw error
      }
    },
    enabled: !!component && !!version
  })
