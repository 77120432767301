import React from 'react'
import { Header, Segment, List, Divider, Button, Icon } from 'semantic-ui-react'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'
import { IReleaseNoteInfo } from '../../../actions/ReleaseNotes'

interface IProps {
  releaseNote?: IReleaseNoteInfo
}

export const ReleaseNoteInformation = (props: IProps) => {
  const getReleaseNoteStatusIcon = (status: boolean | undefined): 'check' | 'x' => (status ? 'check' : 'x')

  const getStatusTextColour = (status: boolean | undefined) => (status ? 'green' : 'red')

  const { releaseNote } = props

  return (
    <div>
      <Header as="h3">Release Note Info</Header>
      <Segment color="blue">
        <LoaderWrap loading={!releaseNote}>
          <React.Fragment>
            <List name="name-list">
              <List.Item>
                <List.Header>Component</List.Header>
                {releaseNote?.db?.componentName}
              </List.Item>
              <List.Item>
                <List.Header>Version</List.Header>
                {releaseNote?.db?.releaseVersion}
              </List.Item>
            </List>
            <Divider horizontal> Details </Divider>
            <List name="info-list">
              <List.Item>
                <List.Icon name={'calendar alternate'} />
                <List.Content>
                  <List.Header>Release Date</List.Header>
                  {releaseNote?.jira?.releaseDate}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name={getReleaseNoteStatusIcon(releaseNote?.db?.released)} />
                <List.Content>
                  <List.Header>Status</List.Header>
                  <p style={{ color: getStatusTextColour(releaseNote?.db?.released) }}>
                    {releaseNote?.db?.released ? 'Released' : 'Pending'}
                  </p>
                </List.Content>
              </List.Item>
            </List>
            <Button
              fluid
              icon
              labelPosition="left"
              href={`https://skedulo.atlassian.net/projects/ENG/versions/${releaseNote?.jira?.id}`}
            >
              <Icon name="ticket" />
              View on Jira
            </Button>
          </React.Fragment>
        </LoaderWrap>
      </Segment>
    </div>
  )
}
