import dayjs from 'dayjs'
import _ from 'lodash'
import * as React from 'react'
import { useEffect } from 'react'
import { Button, Checkbox, Divider, Grid, Modal, Segment } from 'semantic-ui-react'
import { createErrorToast } from '../../alertComponents/Alert'
import {
  completeReleaseNote,
  createReleaseNote,
  INewReleaseNote,
  IReleaseNoteInfo
} from '../../../actions/ReleaseNotes'
import { useGetReleaseNotesQuery } from '../../../queries/releaseNotes/GetReleaseNotesQuery'
import { Spinner } from '../../../App'
import { CreateReleaseNotesForm } from './CreateReleaseNotesForm'

interface IProps {
  releaseNoteInfo?: IReleaseNoteInfo
  disabled: boolean
  check: boolean
  checkMessage: string[]
  approveHook?: () => Promise<any>
}

export const CompleteReleaseNoteModal = (props: IProps) => {
  const { releaseNoteInfo } = props

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [releaseNoteChecks, setReleaseNoteChecks] = React.useState<boolean[]>(
    Array(props.checkMessage.length).fill(false)
  )
  const [createNextReleaseNotes, setCreateNextReleaseNotes] = React.useState<boolean>(true)
  const [initNextReleaseNote, setInitNextReleaseNote] = React.useState<Partial<INewReleaseNote>>({})
  const [nextReleaseNote, setNextReleaseNote] = React.useState<INewReleaseNote | undefined>(undefined)

  const componentName = releaseNoteInfo?.db.componentName
  const releaseNoteInfoDefined = releaseNoteInfo !== undefined

  useEffect(() => {
    if (releaseNoteInfoDefined) {
      const nextReleaseDate = dayjs(new Date()).add(2, 'week')
      setInitNextReleaseNote({
        componentName,
        releaseDate: nextReleaseDate.toDate()
      })
    }
  }, [releaseNoteInfoDefined, componentName])

  const toggleReleaseNoteCheck = (indx: number) => () => {
    const checks = [...releaseNoteChecks]
    checks[indx] = !checks[indx]
    setReleaseNoteChecks(checks)
  }

  const disableApprove = props.check && !releaseNoteChecks.reduce((prev, curr) => prev && curr, true) // AND all delveryChecks values

  const handleCompleteReleaseNote = async () => {
    setLoading(true)
    if (releaseNoteInfo !== undefined) {
      await completeReleaseNote(releaseNoteInfo.db.componentName, releaseNoteInfo.db.releaseVersion).catch(error =>
        createErrorToast(error)
      )
    }
    if (createNextReleaseNotes && nextReleaseNote) {
      await createReleaseNote(nextReleaseNote).catch(error => createErrorToast(error))
    }
    if (!_.isUndefined(props.approveHook)) {
      await props.approveHook()
    }
    setLoading(false)
    setOpen(false)
  }

  const { data: releaseNotes } = useGetReleaseNotesQuery()

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      trigger={
        <Button positive fluid onClick={() => setOpen(true)} disabled={props.disabled}>
          {releaseNoteInfo ? 'Complete Release Notes' : <Spinner />}
        </Button>
      }
    >
      <Modal.Header>Complete Release Note</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to complete release notes <b>{releaseNoteInfo?.db.componentName}</b> version{' '}
          <b>{releaseNoteInfo?.db.releaseVersion}</b>?
        </p>
        {props.checkMessage.map((message, indx) => (
          <Segment key={indx} id={'checks'}>
            {message}
            <Divider />
            <Checkbox
              key={indx}
              checked={releaseNoteChecks[indx]}
              toggle
              label={'Approve anyway.'}
              onClick={toggleReleaseNoteCheck(indx)}
            />
          </Segment>
        ))}
        <Divider />
        <Checkbox
          id="createNextReleaseNotes"
          label={<label htmlFor="createNextReleaseNotes">Create Next Release Notes</label>}
          toggle
          checked={createNextReleaseNotes}
          onChange={() => setCreateNextReleaseNotes(!createNextReleaseNotes)}
        />
        {createNextReleaseNotes && (
          <Segment>
            <CreateReleaseNotesForm
              releaseNotes={releaseNotes ?? []}
              loading={false}
              onChange={setNextReleaseNote}
              initialValue={initNextReleaseNote}
            />
          </Segment>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Grid columns={2}>
          <Grid.Column>
            <Button name="no" negative fluid content="No" onClick={() => setOpen(false)} />
          </Grid.Column>
          <Grid.Column>
            <Button
              name="yes"
              positive
              fluid
              content="Yes"
              onClick={handleCompleteReleaseNote}
              loading={loading}
              disabled={disableApprove}
            />
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  )
}
