/* eslint-disable @typescript-eslint/quotes */
import {
  ButtonProps,
  DropdownItemProps,
  DropdownProps,
  Form,
  Grid,
  Header,
  Input,
  InputOnChangeData,
  Label,
  Message,
  Image
} from 'semantic-ui-react'
import { upperCase } from 'lodash'
import { IRegionInfo } from '../../../../../actions/Regions'
import { SearchableSelector } from '../../../../../views/formComponents/Selectors'
import { NewTeam } from '../../../../../actions/NewTeams'
import { FormErrors } from '../../../../../views/newTeams/services/CreateTeamService'
import { ownerOptions } from '../../../../../views/newTeams/services/TeamInformationService'
import { RegionFlag } from '../../../../../utils/regionFlags'
import { tooltip } from '../CreateTeamForm'
import { RadioGroup, RadioGroupOption } from '../../../../../components/RadioGroup/RadioGroup'

export interface TeamFormSectionProps {
  title: string
  regionOptions: IRegionInfo[]
  environmentOptions: DropdownItemProps[]
  salesforceTeam: boolean
  newTeam: NewTeam
  errors: FormErrors
  handleTeamInformationInput: (
    _event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: InputOnChangeData | DropdownProps | ButtonProps
  ) => void
  isCreateNelxTeam?: boolean
}

export const getLabelBasedOnHostname = (hostname: string) => {
  if (hostname === 'admin-console.skedulo.com') {
    return '.my.skedulo.com'
  } else if (hostname === 'staging-admin-console.test.skl.io') {
    return '.my-staging.test.skl.io'
  } else {
    return '.my-dev.test.skl.io'
  }
}

const TeamFormSection = ({
  title,
  regionOptions,
  environmentOptions,
  salesforceTeam,
  newTeam,
  errors,
  handleTeamInformationInput,
  isCreateNelxTeam
}: TeamFormSectionProps) => {
  const radioRegionOptions: RadioGroupOption[] = regionOptions.map(option => ({
    value: upperCase(option.region),
    text: option.name,
    display: <RegionFlag region={option.region} />
  }))

  const radioIsSalesforceOptions: RadioGroupOption[] = [
    {
      value: 'skedulo',
      logo: (
        <Image
          inline={true}
          id="radio-logo"
          src={require('../../../../../images/skedulo-logo-icon.svg')} // Workaround for import.meta.url not playing nice with jest.
          size="mini"
          spaced="left"
        />
      ),
      text: 'Skedulo Pulse Platform'
    },
    {
      value: 'salesforce',
      logo: (
        <Image
          inline={true}
          id="radio-logo"
          src={require('../../../../../images/salesforce-logo-icon.png')} // Workaround for import.meta.url not playing nice with jest.
          size="mini"
          spaced="left"
        />
      ),
      text: 'Skedulo for Salesforce',
      display: tooltip('Select this option if this team is going to use the Skedulo for Salesforce managed package')
    }
  ]

  const radioEnvironmentOptions: RadioGroupOption[] = environmentOptions.map(env => ({
    value: env.value as string,
    text: env.text as string
  }))

  return (
    <>
      <Header as="h3">{title}</Header>
      <Grid padded="vertically">
        <Form.Field required width={16} error={errors?.primaryName?.error}>
          <label htmlFor="primaryName">Team name</label>
          <div className="create-team-note">
            {
              'The team name forms the team URL. Use <customername>-<environmenttype> for non-prod teams and <customername> for prod.'
            }
          </div>
          <Form.Input width={12}>
            <Input
              id="primaryName"
              name="primaryName"
              value={newTeam.primaryName}
              placeholder="Enter team name"
              onChange={handleTeamInformationInput}
              label={getLabelBasedOnHostname(window.location.hostname)}
              labelPosition="right"
            />
          </Form.Input>

          {errors?.primaryName?.error && (
            <Label pointing color="red">
              {errors.primaryName.message}
            </Label>
          )}
          {errors?.uniqueTeam?.error && (
            <Label pointing color="red">
              {errors.uniqueTeam.message}
            </Label>
          )}
        </Form.Field>
        <Form.Field required width={16}>
          <label htmlFor="description">Team description</label>
          <div className="create-team-note">
            {
              'A short description that includes the customer name and environment, e.g. Customer Name Test Team. Used in user invitation emails.'
            }
          </div>
          <Form.Input
            id="description"
            name="description"
            value={newTeam.description}
            placeholder="Enter team description"
            onChange={handleTeamInformationInput}
            width={12}
          />
        </Form.Field>
        {!isCreateNelxTeam && (
          <Form.Field required width={16}>
            <label htmlFor="isSalesforce">Platform</label>
            <div className="create-team-note">
              {'Select the correct platform. The platform cannot be changed after the team is created.'}
            </div>
            <RadioGroup
              options={radioIsSalesforceOptions}
              name="isSalesforce"
              value={newTeam.isSalesforce ? 'salesforce' : newTeam.isSalesforce === false ? 'skedulo' : ''}
              handleInput={handleTeamInformationInput}
            />
          </Form.Field>
        )}
        <Form.Field
          required
          width={16}
          error={errors?.teamSalesforceId?.error}
          className={
            salesforceTeam && !isCreateNelxTeam
              ? errors?.teamSalesforceId?.error
                ? 'show sf error'
                : 'show sf'
              : 'hide'
          }
        >
          <label htmlFor="tenantId">
            Salesforce organisation ID{' '}
            {tooltip('Salesforce IDs will be automatically converted to 18 character IDs when creating teams')}
          </label>
          <div className="create-team-note">
            The Organization ID of the customer&apos;s Salesforce environment to link to this team.
          </div>
          <Form.Input
            id="tenantId"
            name="tenantId"
            value={newTeam.tenantId}
            placeholder="Enter Salesforce ID"
            onChange={handleTeamInformationInput}
            width={12}
          />
          {errors?.teamSalesforceId?.error && salesforceTeam && !isCreateNelxTeam && (
            <Label pointing color="red">
              {errors.teamSalesforceId.message}
            </Label>
          )}
        </Form.Field>
        <Form.Field required width={16}>
          <label htmlFor="environment">Environment {tooltip('A label to help identify team types')}</label>
          <div className="create-team-note">
            {
              'Environment types can be changed after the team is created. Production environments cannot be deleted or reset.'
            }
          </div>
          <RadioGroup
            options={radioEnvironmentOptions}
            name="environment"
            value={newTeam.environment || ''}
            handleInput={handleTeamInformationInput}
          />
        </Form.Field>
        <Form.Field required width={16} style={{ marginBottom: '14px' }}>
          <label htmlFor="owner">Owner {tooltip('A label to help identify responsible parties')}</label>
          <div className="create-team-note">{'For customers, select “Customer”.'}</div>
          <SearchableSelector
            id="owner"
            name="owner"
            value={newTeam.owner ?? ''}
            options={ownerOptions}
            onChange={handleTeamInformationInput}
            placeholder="Select owner"
            width={12}
          />
        </Form.Field>

        <Form.Group>
          <Form.Field required>
            <label htmlFor="region">Region</label>
            <Message
              style={{ display: 'flex', marginBottom: '1em' }}
              warning
              icon={'warning'}
              content={
                'Select the correct region. Changing the region after the team is created requires a complicated and manual data migration that can delay go-live.'
              }
            />

            <RadioGroup
              options={radioRegionOptions}
              name="region"
              value={newTeam.region || ''}
              handleInput={handleTeamInformationInput}
            />
          </Form.Field>
        </Form.Group>
      </Grid>
    </>
  )
}

export default TeamFormSection
