import React, { useEffect, useMemo, useState } from 'react'
import {
  Accordion,
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  DropdownProps,
  Grid,
  Header,
  Icon,
  Loader,
  Menu
} from 'semantic-ui-react'

import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useDeliveriesForApplicationAndVersionQuery } from '../../queries/deliveries/GetDeliveriesQuery'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { IReleaseApplication } from '../../actions/Releases'

import { IDeliveryCommitDiffResult, TDeliveryDetails } from '../../actions/Deliveries'

import { IDeployment } from '../../actions/Deployments'
import { IDeploymentTemplateDetails } from '../../actions/Admin'
import { IDeliveryCheck } from '../../actions/DeliveryCheck'
import { useDeliveryChecksQuery } from '../../queries/deliveries/GetDeliveryChecksQuery'
import { useDeploymentsQuery } from '../../queries/deployments/GetDeploymentsQuery'
import { useDeploymentTemplateQuery } from '../../queries/deployments/GetDeploymentTemplateQuery'
import { useDeliveryCommitDiffQuery } from '../../queries/deliveries/GetDeliveryCommitDiffQuery'
import { useRunDeliveryChecksMutation } from '../../mutations/deliveries/RunDeliveryChecks'
import { DeployDeliveryModal } from './Components/DeployDeliveryModal'
import { DeliveryCommitsList } from './Components/DeliveryCommitsList'
import { DeliveryInformation } from './Components/DeliveryInformation'
import { RejectDeliveryModal } from './Components/RejectDeliveryModal'

import { DeliveryCompare } from './Components/DeliveryCompare'
import { DeliveriesDeploymentInfo, ISimpleInstace } from './Components/DeliveriesDeploymentInfo'
import { DeliveriesChecksInfo } from './Components/DeliveriesChecksInfo'

const title = 'Delivery Details'

const isExpired = (expiredAt: string): boolean => !!expiredAt && new Date(expiredAt) <= new Date()

const categorizeChecksBasedOnStatus = (deliveryChecks?: IDeliveryCheck[]) => {
  const pending = deliveryChecks?.filter(check => check.status === 'created' && !isExpired(check.expiresAt)) || []
  const failed = deliveryChecks?.filter(check => check.status === 'failed' && !isExpired(check.expiresAt)) || []
  const warning = deliveryChecks?.filter(check => check.status === 'warning' && !isExpired(check.expiresAt)) || []
  const passed = deliveryChecks?.filter(check => check.status === 'passed' && !isExpired(check.expiresAt)) || []
  const expired = deliveryChecks?.filter(check => isExpired(check.expiresAt)) || []
  return {
    pending,
    failed,
    warning,
    passed,
    expired
  }
}

export const processDeployments = (
  testDeployments?: IDeployment[],
  testDeploymentTemplate?: IDeploymentTemplateDetails
) => {
  const missing = testDeploymentTemplate?.environments
    .filter(
      (
        env // check each env in test, staging has a deployment
      ) =>
        (env.name === 'test' || env.name === 'staging') &&
        !testDeployments?.some(dep => dep.env === env.name && dep.instance === env.instance)
    )
    .map(env => ({ environment: env.name, instance: env.instance }))

  const checkedEnvs: ISimpleInstace[] = []
  const failed: ISimpleInstace[] = []
  const succeeded: ISimpleInstace[] = []
  const pending: ISimpleInstace[] = []
  const running: ISimpleInstace[] = []
  testDeployments
    ?.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp)) // sort newest first
    .forEach(dep => {
      // check each deployment status
      const envName = { environment: dep.env, instance: dep.instance }
      if (!checkedEnvs.some(env => env.environment === envName.environment && env.instance === envName.instance)) {
        // not yet checked
        checkedEnvs.push(envName)
        switch (dep.status) {
          case 'successful':
            succeeded.push(envName)
            break
          case 'running':
            running.push(envName)
            break
          case 'pending':
            pending.push(envName)
            break
          default:
            failed.push(envName)
        }
      }
    })
  return { failed, succeeded, pending, running, missing: missing || [] }
}

const getListOfCommits = (compareInstance: string, commitList?: IDeliveryCommitDiffResult) => {
  if (commitList === undefined) {
    return []
  }
  const env = commitList.environments.find(e => e.environment === compareInstance)
  if (env !== undefined) {
    const diff = commitList.versions.find(v => v.version === env.version)
    return diff?.commitDiff || []
  }
  return []
}

export const DeliveryDetails = () => {
  const [compareInstance, setCompareInstance] = useState('')
  // commits
  const [openCommitDetails, setOpenCommitDetails] = useState(true)

  const params = useParams()
  const navigate = useNavigate()

  useDocumentTitle(title)

  const applicationName = params.applicationName!
  const version = params.version!

  const { data: delivery, status: deliveryStatus } = useDeliveriesForApplicationAndVersionQuery(
    applicationName,
    version
  )

  const { status: deploymentsStatus, data: deployments } = useDeploymentsQuery()

  const { status: deliveryChecksStatus, data: deliveryChecks } = useDeliveryChecksQuery(
    applicationName,
    version,
    !!delivery
  )

  const { data: deploymentTemplate } = useDeploymentTemplateQuery(delivery?.application.repositoryName)

  const { fetchStatus: deliveryCommitsFetchStatus, data: deliveryCommits } = useDeliveryCommitDiffQuery(
    applicationName,
    version,
    delivery?.status === 'pending'
  )

  const runDeliveryChecks = useRunDeliveryChecksMutation()

  const changeCompareInstance = (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    setCompareInstance(String(data.value))
  }

  const getEnvironmentPickerOptions = () =>
    deliveryCommits?.environments.map(env => ({
      key: env.environment,
      value: env.environment,
      text: env.environment
    }))

  const gitHubCompareURL = () => {
    if (
      compareInstance === undefined ||
      delivery === undefined ||
      delivery.application === undefined ||
      deliveryCommits === undefined
    ) {
      return undefined
    } else {
      const gitHash = deliveryCommits.environments.find(({ environment }) => environment === compareInstance)?.version
      return `https://github.com/${delivery.application.repositoryOwner}/${delivery.application.repositoryName}/compare/${gitHash}..${delivery.tag}`
    }
  }

  const {
    pending: pendingChecks,
    failed: failedChecks,
    warning: warningChecks,
    passed: passedChecks,
    expired: expiredChecks
  } = categorizeChecksBasedOnStatus(deliveryChecks)

  const deploymentCheck = () =>
    deploymentsStatus !== 'loading' &&
    deliveryChecksStatus !== 'loading' &&
    failedDeployments.length + missingDeployments.length + runningDeployments.length + pendingDeployments.length ===
      0 &&
    passedChecks.length === deliveryChecks?.length

  const deploymentGateMessage = () => {
    const message = []
    if (deploymentsStatus === 'loading') {
      message.push('Checking deployments...')
    }
    if (failedDeployments.length) {
      message.push('This build has failed deployments to some environments.')
    }
    if (missingDeployments.length) {
      message.push(
        // prettier-ignore
        'This build has not been deployed to some staging and test environments included in the application\'s deployment template.'
      )
    }
    if (pendingDeployments.length) {
      message.push('This build has outstanding pending deployments to some environments.')
    }
    if (runningDeployments.length) {
      message.push('This build has outstanding running deployments to some environments.')
    }

    failedChecks.forEach(check => {
      const failedMessage = `'${check.name}' failed. Please re-run (if applicable) or ensure you have checked the details before proceeding.`
      message.push(failedMessage)
    })
    expiredChecks.forEach(check => {
      const expiredMessage = `'${check.name}' expired at ${check.expiresAt}. Please re-run this check before proceeding.`
      message.push(expiredMessage)
    })
    warningChecks.forEach(check => {
      const warningMessage = `'${check.name}' is in warning status. Please ensure you have checked the details before proceeding.`
      message.push(warningMessage)
    })

    return message
  }

  const filteredDeployments = useMemo(
    () =>
      deployments?.filter(
        dep => dep.application === delivery?.application.repositoryName && dep.tag === delivery?.tag
      ) || [],
    [deployments, delivery]
  )

  const {
    failed: failedDeployments,
    succeeded: successfulDeployments,
    pending: pendingDeployments,
    running: runningDeployments,
    missing: missingDeployments
  } = useMemo(
    () => processDeployments(filteredDeployments, deploymentTemplate),
    [filteredDeployments, deploymentTemplate]
  )

  if (deliveryCommits && deliveryCommits.environments.length > 0 && delivery?.status === 'pending') {
    if (!compareInstance) {
      setCompareInstance(deliveryCommits.environments[0].environment)
    }
  }

  const commitDiff = useMemo(
    () => getListOfCommits(compareInstance, deliveryCommits),
    [deliveryCommits, compareInstance]
  )

  const handleRerunDeliveryChecks = async () => {
    runDeliveryChecks.mutate({ application: applicationName, version })
  }

  useEffect(() => {
    if (deliveryStatus === 'error' && !delivery) {
      navigate('/applications/deliveries')
    }
  }, [deliveryStatus, delivery, navigate])

  const environmentDropdownOptions = getEnvironmentPickerOptions()

  return (
    <div className="route-component">
      <span>
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={() => navigate(-1)}>
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Menu.Item>
              <Grid columns={2}>
                <Grid.Column width={8}>
                  <DeployDeliveryModal
                    delivery={delivery}
                    disabled={delivery?.status !== 'pending'}
                    check={!deploymentCheck()}
                    checkMessage={[...deploymentGateMessage()]}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <RejectDeliveryModal delivery={delivery} disabled={delivery?.status !== 'pending'} />
                </Grid.Column>
              </Grid>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid columns={2} stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={3} style={{ height: '100%' }}>
            <DeliveryInformation delivery={delivery || ({} as TDeliveryDetails)} />
          </Grid.Column>
          <Grid.Column width={13} style={{ height: '100%' }} className="scrollable-no-margin">
            <Divider />
            <DeliveriesChecksInfo
              deliveryStatus={delivery?.status}
              deliveryChecks={deliveryChecks || []}
              pending={pendingChecks.length}
              warning={warningChecks.length}
              failed={failedChecks.length}
              expired={expiredChecks.length}
              passed={passedChecks.length}
              loading={deliveryChecksStatus === 'loading'}
              handleRerunCheck={handleRerunDeliveryChecks}
              isExpired={isExpired}
            />
            <Divider />
            <DeliveriesDeploymentInfo
              deployments={filteredDeployments}
              loading={deploymentsStatus === 'loading'}
              failed={failedDeployments}
              missing={missingDeployments}
              pending={pendingDeployments}
              running={runningDeployments}
              successful={successfulDeployments}
            />
            <Divider />
            <Accordion>
              <Accordion.Title active={openCommitDetails} onClick={() => setOpenCommitDetails(prevState => !prevState)}>
                <Grid columns={2}>
                  <Grid.Column floated="left" verticalAlign="middle">
                    <Header as="h3">
                      <Header.Content>
                        {(environmentDropdownOptions && environmentDropdownOptions.length > 0) ||
                        deliveryCommitsFetchStatus === 'fetching' ? (
                          <>
                            <Icon name="dropdown" />
                            Commits to be added to{' '}
                            <Dropdown
                              icon={
                                <Icon name="dropdown" style={{ transform: 'rotate(90deg)' }} />
                                // setting transform overwrites the transform inherited from active accordion title
                              }
                              value={compareInstance}
                              onChange={changeCompareInstance}
                              loading={deliveryCommitsFetchStatus === 'fetching'}
                              options={deliveryCommitsFetchStatus === 'fetching' ? [] : environmentDropdownOptions}
                              onClick={evt => {
                                evt.stopPropagation()
                              }}
                            />
                          </>
                        ) : (
                          'No environments found to compare'
                        )}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column floated="right" textAlign="right">
                    {delivery?.status === 'pending' && (
                      <Button
                        basic
                        compact
                        icon
                        labelPosition="left"
                        href={gitHubCompareURL()}
                        target="_blank"
                        name="compare_button"
                        onClick={evt => {
                          evt.stopPropagation()
                        }}
                      >
                        <Icon name="github" />
                        Compare to {compareInstance || <Loader active inline size="tiny" />}
                      </Button>
                    )}
                  </Grid.Column>
                </Grid>
              </Accordion.Title>
              <Accordion.Content active={openCommitDetails}>
                <DeliveryCommitsList
                  loading={deliveryCommitsFetchStatus === 'fetching'}
                  commits={commitDiff}
                  application={delivery?.application || ({} as IReleaseApplication)}
                  emptyMessage={delivery?.status !== 'pending' ? 'This delivery has been completed.' : undefined}
                />
              </Accordion.Content>
            </Accordion>
            <Divider />
            <DeliveryCompare thisDelivery={delivery} />
            <Divider />
          </Grid.Column>
        </Grid>
      </span>
    </div>
  )
}
