import * as _ from 'lodash'
import { CUSTOMER_LOGIN_TYPE_REQUEST } from '../views/newCustomers/services/Constants'

export interface DynamoQueryIndex {
  pk: string
  sk: string
  gs1pk: string
  gs1sk: string
}

export interface DynamoQueryFilter {
  filter?: string
  filterKeys?: Record<string, string>
}

export type DynamoQuery = DynamoQueryFilter & {
  pageSize?: number
  next?: DynamoQueryIndex
  prev?: DynamoQueryIndex
}

export interface DynamoQueryResponse<T> {
  next?: DynamoQueryIndex
  prev?: DynamoQueryIndex
  results: T[]
}

export interface IResult<T> {
  result: T
}

export interface IResults<T> {
  results: T[]
}

export interface ICreated<T> {
  created: T
}

export type Vendor = '' | 'salesforce' | 'skedulo'

// eslint-disable-next-line no-shadow
export enum VendorClaim {
  Salesforce = 'salesforce',
  Skedulo = 'skedulo'
}

export interface IMultiEnvironmentError<T> {
  message?: string
  errors: string[]
  successful?: T
}

export const decodeErrors = <T>(
  error: any,
  setData: (data: T) => void,
  handleError: (parsedError: unknown) => void
) => {
  if (error.response && error.response.status === 400) {
    if (error.response.data) {
      return handleError(decodeErrorData(error.response.data, setData))
    }
  }
  return handleError(error)
}

export const decodeErrorData = <T>(errorData: any, setData: (data: T) => void) => {
  if (_.isObject(errorData)) {
    const data = errorData as IMultiEnvironmentError<T>
    let message = data.message ?? 'Errors: '
    if (data.successful) {
      setData(data.successful)
    }
    if (_.isArray(data.errors)) {
      message = data.errors.reduce((errors: string, currentError: string) => `${errors} ${currentError}`, message)
    }
    return new Error(message)
  }
  return new Error(`${errorData}`)
}

export const getDisplayedLoginOptionType = (loginOptionType: string) =>
  Object.keys(CUSTOMER_LOGIN_TYPE_REQUEST).find(
    key => CUSTOMER_LOGIN_TYPE_REQUEST[key as keyof typeof CUSTOMER_LOGIN_TYPE_REQUEST] === loginOptionType
  )

export const decodeAccessToken = (token?: string) => {
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/') || ''
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    )
    return JSON.parse(jsonPayload)['https://admin.skl.io/permissions']
  }
}
