import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Dimmer,
  Divider,
  DropdownProps,
  Form,
  Grid,
  Icon,
  InputProps,
  Loader,
  Message,
  Modal,
  Popup,
  Segment
} from 'semantic-ui-react'

import { IReleaseApplication } from '../../../actions/Releases'
import { ApplicationSelector } from '../../formComponents/Selectors'
import { useCreateDeliveryMutation } from '../../../mutations/deliveries/CreateDeliveryMutation'
import { useDeliveriesNextForApplication } from '../../../queries/deliveries/GetDeliveriesQuery'

interface IProps {
  applications: IReleaseApplication[]
}

export const CreateDeliveryModal = (props: IProps) => {
  const [open, setOpen] = useState(false)
  const [applicationName, setApplicationName] = useState('')
  const [version, setVersion] = useState('')
  const [tag, setTag] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [pinConfiguration, setPinConfiguration] = useState(false)
  const [configVersion, setConfigVersion] = useState('')

  const { mutate: createDelivery } = useCreateDeliveryMutation()

  const changeApplication = (_event: React.SyntheticEvent, data: DropdownProps) => {
    setApplicationName(String(data.value))
  }

  const changeTag = (_event: React.SyntheticEvent, data: InputProps | DropdownProps) => {
    setTag(data.value)
  }

  const {
    data: deliveryResult,
    status: deliveryResultStatus,
    fetchStatus: deliveryResultFetchStatus
  } = useDeliveriesNextForApplication(applicationName)

  useEffect(() => {
    if (deliveryResultStatus === 'success') {
      setVersion(deliveryResult.version)
      setPinConfiguration(deliveryResult.delivery?.pinConfiguration ?? false)
      setTag(deliveryResult.delivery?.tag ?? '')
      setConfigVersion(deliveryResult.delivery?.configVersion ?? '')
    }
  }, [deliveryResult, deliveryResultStatus])

  const handleCreateDelivery = async () => {
    setSubmitting(true)
    createDelivery({
      applicationName,
      version,
      tag,
      status: 'pending',
      testsPass: false,
      ...(pinConfiguration && {
        pinConfiguration,
        configVersion
      })
    })
    clearApplication()
    setOpen(false)
    setSubmitting(false)
  }

  const submit = async () => {
    if (applicationName) {
      await handleCreateDelivery()
    }
  }

  const clearApplication = useCallback(() => {
    setApplicationName('')
    setVersion('')
    setTag('')
    setPinConfiguration(false)
  }, [setApplicationName, setVersion, setTag, setPinConfiguration])

  const closeModal = useCallback(() => {
    setOpen(false)
    clearApplication()
  }, [setOpen, clearApplication])

  const openModal = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const deliveryMessageIsValid = (): boolean => Boolean(deliveryResult?.message?.length)

  const CONFIG_VERSION_HELP =
    'Configuration version will only be correctly set when all test environments where the application is running have the same config version'

  return (
    <Modal
      closeIcon
      open={open}
      onClose={closeModal}
      trigger={<Button className="form-button-sked-blue" primary content="Create Delivery" onClick={openModal} />}
      aria-label="Create Delivery"
    >
      <Modal.Header>Create Delivery</Modal.Header>
      <Modal.Content>
        <Form onSubmit={submit}>
          <ApplicationSelector
            applications={props.applications}
            value={applicationName}
            onChange={changeApplication}
            label="Application"
            width={8}
          />
          {deliveryMessageIsValid() && (
            <Message icon="exclamation circle" header={applicationName} content={deliveryResult?.message} />
          )}
          <Divider />
          <Dimmer.Dimmable dimmed={deliveryResultFetchStatus === 'fetching'}>
            <Dimmer active={deliveryResultFetchStatus === 'fetching'} inverted>
              <Loader>Loading</Loader>
            </Dimmer>
            <Form.Field required disabled={!applicationName}>
              <label>Version</label>
              <Segment
                content={applicationName && deliveryResultStatus !== 'loading' ? version : 'Delivery Version'}
                disabled={!applicationName}
              />
            </Form.Field>
            <Form.Input
              label="Commit"
              placeholder="Commit Hash"
              required
              name="tag"
              onChange={changeTag}
              value={tag}
              disabled={!applicationName}
            />
            <Form.Checkbox
              label={<label htmlFor="configuration-pinning">Pin Configuration</label>}
              id="configuration-pinning"
              name="pinConfiguration"
              checked={pinConfiguration}
              onChange={(_e, data) => setPinConfiguration(data.checked ?? false)}
              disabled={!applicationName}
              toggle
            />
            <Form.Input
              label={
                <label htmlFor="config-version">
                  Config Version <Popup content={CONFIG_VERSION_HELP} trigger={<Icon name="question circle" />} />
                </label>
              }
              id="config-version"
              placeholder="Latest"
              name="configVersion"
              onChange={(_event, data) => setConfigVersion(data.value)}
              value={configVersion}
              disabled={!applicationName || !pinConfiguration}
            />
          </Dimmer.Dimmable>
          <Divider />
        </Form>
        <Message
          icon="exclamation circle"
          header="Creating a delivery will deploy the selected tag to staging."
          content={`${tag || '---'} will be deployed to staging.`}
        />
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column floated="left" width={4}>
              <Form.Button
                id="modal-submit-no-padding"
                type="submit"
                className="modal-submit-no-padding"
                content="Submit"
                disabled={!(applicationName && tag)}
                loading={submitting}
                fluid
                onClick={submit}
              />
            </Grid.Column>
            <Grid.Column floated="right" width={4} verticalAlign="bottom">
              <Form.Button type="reset" content="Reset" onClick={clearApplication} fluid />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}
