import { IDeployment } from './Deployments'
import { adminApi } from './Endpoints'

export interface IRelease {
  id: number
  applicationName: string
  version: string
  tag: string
  hotfix: boolean
  staged: boolean
  released: boolean
  releasedDate?: string
  isCD?: boolean
}

export interface IReleaseApplication {
  applicationName: string
  component: string
  repositoryOwner: string
  repositoryName: string
  normalRelease: boolean
  deliveryCheckTemplateId?: string
}

export interface ICutReleaseBody {
  version: string
  hotfixBaseVersion?: string
  force?: boolean
}

export interface ICutReleaseResponse {
  release: IRelease
  releaseNotes?: object
}

export interface ICompleteReleaseResponse {
  release: IRelease
  tickets: {
    succeeded: object[]
    already_released: object[]
  }
  releaseTransitioned: boolean
}

export const getReleases = async (): Promise<IRelease[]> =>
  adminApi.get<IRelease[]>('/release').then(response => response.data)

export const getRelease = async (applicationName: string, version: string): Promise<IRelease> =>
  adminApi.get<IRelease>(`/release/${applicationName}/${version}`).then(response => response.data)

export const getReleasesForVersion = async (version: string): Promise<IRelease[]> =>
  adminApi.get<IRelease[]>(`/release/${version}`).then(response => response.data)

export const getReleasesForApplication = async (application: string): Promise<IRelease[]> =>
  adminApi.get<IRelease[]>(`release/application/${application}/releases`).then(response => response.data)

export const getLastApplicationRelease = async (application: string, version?: string, releasedDate?: Date) =>
  adminApi
    .get<IRelease>(`release/application/${application}/releases/latest`, { params: { version, releasedDate } })
    .then(response => response.data)

export const getReleaseApplication = async (applicationName: string): Promise<IReleaseApplication> =>
  adminApi.get<IReleaseApplication>(`/release/application/${applicationName}`).then(response => response.data)

export const getReleaseApplications = async (): Promise<IReleaseApplication[]> =>
  adminApi.get<IReleaseApplication[]>('/release/application').then(response => response.data)

export const cutRelease = async (
  applicationName: string,
  version: string,
  force?: boolean,
  hotfixBaseVersion?: string
): Promise<ICutReleaseResponse> => {
  const data = { version } as ICutReleaseBody
  if (force) {
    data.force = force
  }
  if (hotfixBaseVersion) {
    data.hotfixBaseVersion = hotfixBaseVersion
  }
  return adminApi.post<ICutReleaseResponse>(`/release/cut/${applicationName}`, data).then(response => response.data)
}

export const deployRelease = async (applicationName: string, version: string): Promise<IDeployment> =>
  adminApi.post<IDeployment>(`/release/deploy/${applicationName}`, { version }).then(response => response.data)

export const completeRelease = async (applicationName: string, version: string): Promise<ICompleteReleaseResponse> =>
  adminApi
    .post<ICompleteReleaseResponse>(`/release/complete/${applicationName}`, { version })
    .then(response => response.data)
