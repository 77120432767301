import { useQuery } from '@tanstack/react-query'
import { getDeploymentTemplateDetails, getDeploymentTemplates } from '../../actions/Admin'
import { createErrorToast } from '../../views/alertComponents/Alert'

export const useDeploymentTemplateQuery = (templateName?: string) =>
  useQuery({
    queryKey: ['deploymentTemplate', templateName],
    queryFn: () => getDeploymentTemplateDetails(templateName!),
    enabled: !!templateName,
    onError: error => createErrorToast(error)
  })

export const useDeploymentTemplatesQuery = () =>
  useQuery({
    queryKey: ['deploymentTemplates'],
    queryFn: () => getDeploymentTemplates(),
    onError: error => createErrorToast(error)
  })
