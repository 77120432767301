import { CSSProperties, MutableRefObject, useMemo } from 'react'
import { Column, useTable, TableOptions, Row } from 'react-table'
import { Table as SemanticTable, Segment, SemanticCOLORS, StrictTableCellProps } from 'semantic-ui-react'
import { TableLoaderWrap } from '../../views/tableComponents/TableLoader'

export type MemoizedColumns<D extends object = object> = Column<D>[]

export type MemoizedData<D extends object = object> = D[]

type CreateMemoizedColumns = <D extends object = object>(columns: Column<D>[], deps?: unknown[]) => MemoizedColumns<D>

type CreateMemoizedData = <D extends object = object>(data: D[], deps?: unknown[]) => MemoizedData<D>

const defaultPropGetter = () => ({})

export interface TableProps<D extends object> {
  columns: Column<D>[]
  data: D[]
  loading?: boolean
  emptyMessage: string
  className?: string
  style?: CSSProperties
  color?: SemanticCOLORS
  withSort?: boolean
  pageSize?: number
  topTableRef?: MutableRefObject<HTMLElement | null>
  size?: 'small' | 'large'
  striped?: boolean
  celled?: boolean
  getRowProps?: (row: Row<D>) => Record<string, any>
  textAlign?: StrictTableCellProps['textAlign']
  tableOptions?: TableOptions<D>
  isPaginated?: boolean
}

export const Table = <D extends object>({
  columns,
  data,
  loading,
  emptyMessage,
  className,
  style,
  color,
  size = 'small',
  striped = true,
  celled = false,
  getRowProps = defaultPropGetter,
  textAlign = 'center',
  tableOptions
}: TableProps<D>) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<D>({
    columns,
    data,
    ...tableOptions
  })

  return (
    <>
      <SemanticTable
        {...getTableProps()}
        stackable
        celled={celled}
        className={className}
        style={style}
        color={color}
        size={size}
        striped={striped}
      >
        <SemanticTable.Header>
          {headerGroups.map(headerGroup => {
            const { key: headerKey, ...headerProps } = headerGroup.getHeaderGroupProps()
            return (
              <SemanticTable.Row {...headerProps} key={headerKey}>
                {headerGroup.headers.map(column => (
                  <SemanticTable.HeaderCell textAlign={textAlign} key={column.id}>
                    {column.render('Header')}
                  </SemanticTable.HeaderCell>
                ))}
              </SemanticTable.Row>
            )
          })}
        </SemanticTable.Header>
        <TableLoaderWrap array={rows} loading={!!loading} emptyMessage={emptyMessage}>
          <SemanticTable.Body>
            {rows.map(row => {
              prepareRow(row)
              const { key: rowKey, ...rowProps } = row.getRowProps(getRowProps(row))

              return (
                <SemanticTable.Row {...rowProps} key={rowKey}>
                  {row.cells.map(cell => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps()
                    return (
                      <SemanticTable.Cell key={cellKey} {...cellProps} textAlign={textAlign}>
                        {cell.render('Cell')}
                      </SemanticTable.Cell>
                    )
                  })}
                </SemanticTable.Row>
              )
            })}
          </SemanticTable.Body>
        </TableLoaderWrap>
      </SemanticTable>
    </>
  )
}

export const createMemoizedColumns: CreateMemoizedColumns = (columns, deps = []) => useMemo(() => columns, [...deps])

export const createMemoizedData: CreateMemoizedData = (data, deps = []) => useMemo(() => data, [...deps])

interface ExampleData {
  firstName: string
  lastName: string
}

export const UseExampleTable = () => {
  const columns = createMemoizedColumns<ExampleData>([
    {
      Header: 'First Name',
      accessor: 'firstName'
    },
    {
      Header: 'Last Name',
      accessor: 'lastName'
    }
  ])

  const data = createMemoizedData<ExampleData>([
    { firstName: 'John', lastName: 'Smith' },
    { firstName: 'Jeff', lastName: 'Killah' },
    { firstName: 'Carl', lastName: 'Gim' }
  ])

  return (
    <Segment color="blue">
      <Table<ExampleData> columns={columns} data={data} emptyMessage="Example not found" />
    </Segment>
  )
}
