import React, { useCallback } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { INewReleaseNote, IReleaseNote } from '../../../actions/ReleaseNotes'
import 'react-datepicker/dist/react-datepicker.css'
import { useCreateReleaseNote } from '../../../mutations/releaseNotes/CreateReleaseNoteMutation'
import { CreateReleaseNotesForm } from './CreateReleaseNotesForm'

dayjs.extend(isoWeek)

interface IProps {
  releaseNotes: IReleaseNote[]
  loading: boolean
}

export const CreateReleaseNotesModal = (props: IProps) => {
  const [open, setOpen] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [releaseNote, setReleaseNote] = React.useState<INewReleaseNote | undefined>(undefined)
  const { mutate: createReleaseNote } = useCreateReleaseNote()

  const handleCreateReleaseNote = useCallback(() => {
    if (releaseNote?.componentName && releaseNote?.releaseVersion) {
      setSubmitting(true)
      createReleaseNote(releaseNote)
      setSubmitting(false)
      setOpen(false)
    }
  }, [setSubmitting, setOpen, createReleaseNote, releaseNote])

  const updateNewReleaseNote = useCallback(
    (newReleaseNote: INewReleaseNote | undefined) => {
      setReleaseNote(newReleaseNote)
    },
    [setReleaseNote]
  )

  const isSubmitDisabled = props.loading || submitting || !releaseNote?.componentName || !releaseNote?.releaseVersion

  const closeModal = useCallback(() => setOpen(false), [setOpen])
  const openModal = useCallback(() => setOpen(true), [setOpen])

  return (
    <Modal
      closeIcon
      onClose={closeModal}
      open={open}
      trigger={
        <Button disabled={props.loading} onClick={openModal}>
          Create Release Notes
        </Button>
      }
    >
      <Modal.Header>Create Release Notes</Modal.Header>
      <Modal.Content>
        <CreateReleaseNotesForm
          releaseNotes={props.releaseNotes}
          loading={props.loading || submitting}
          onChange={updateNewReleaseNote}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button id="submit" color="green" onClick={handleCreateReleaseNote} disabled={isSubmitDisabled}>
          Submit
        </Button>
        <Button id="cancel" color="black" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
