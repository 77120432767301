import { Row } from 'react-table'
import { Icon, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Customer } from '../../../actions/NewCustomers'
import { createMemoizedColumns } from '../../../components/TableComponents/ReactTable'
import { CustomersService } from '../services/CustomersService'
import {
  getValuesFromDynamoQuery,
  InfinitePaginatedTable
} from '../../../components/TableComponents/InfinitePaginatedTable'
import { formatDate } from '../../../utils/dateUtils'
import { RegionFlag } from '../../../utils/regionFlags'

export const NewCustomersList = (customersService: CustomersService) => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = customersService
  const customerColumns = createMemoizedColumns<Customer>([
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }: { row: Row<Customer> }) => <Link to={`/customers/${row.original.id}`}>{row.original.name}</Link>
    },
    {
      Header: 'Salesforce ID',
      accessor: 'salesforceId',
      Cell: ({ row }: { row: Row<Customer> }) =>
        row.original.salesforceId ? (
          <a
            href={`https://sked.lightning.force.com/lightning/r/Account/${row.original.salesforceId}/view`}
            target="_blank"
            rel="noreferrer"
          >
            {row.original.salesforceId}{' '}
            <span>
              <Icon name="external" />
            </span>
          </a>
        ) : (
          <p style={{ fontStyle: 'italic', color: '#d1d5db' }}>N/A</p>
        )
    },
    {
      Header: 'Region',
      accessor: 'region',
      Cell: ({ row }: { row: Row<Customer> }) =>
        row.original.region ? (
          <Popup
            content={row.original.region}
            position="top left"
            trigger={
              <div>
                <RegionFlag region={row.original.region} />
              </div>
            }
            style={{
              opacity: 0.9,
              textAlign: 'center'
            }}
            inverted
          />
        ) : (
          <p style={{ fontStyle: 'italic', color: '#d1d5db' }}>N/A</p>
        )
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: ({ row }: { row: Row<Customer> }) => formatDate(row.original.createdAt)
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ row }: { row: Row<Customer> }) => formatDate(row.original.updatedAt)
    }
  ])
  return (
    <InfinitePaginatedTable
      loading={isLoading}
      columns={customerColumns}
      data={data}
      emptyMessage="No customers found (search is case sensitive)"
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      fetchingNextPage={isFetchingNextPage}
      getValuesFromData={getValuesFromDynamoQuery}
      scrollable
    />
  )
}
