import { Icon, Popup } from 'semantic-ui-react'

interface IProps {
  status: string
  checkMode: boolean
  explanation?: string
}

export const DeploymentStatus = (props: IProps) => {
  switch (props.status) {
    case 'successful':
      return (
        <Icon
          name={props.checkMode ? 'info circle' : 'check circle'}
          color={props.checkMode ? 'blue' : 'green'}
          size="large"
          aria-label={props.status}
        />
      )
    case 'failed':
    case 'error':
      return props.explanation ? (
        <Popup
          content={props.explanation}
          trigger={<Icon name="minus circle" color="red" size="large" aria-label={props.status} />}
        />
      ) : (
        <Icon
          name={props.checkMode ? 'info circle' : 'times circle'}
          color="red"
          size="large"
          aria-label={props.status}
        />
      )
    case 'pending':
    case 'waiting':
      return <Icon name="pause circle" size="large" aria-label={props.status} />
    case 'running':
      return <Icon name="refresh" loading size="large" aria-label={props.status} />
    case 'cancelled':
    case 'canceled':
      return <Icon name="warning sign" color="orange" size="large" aria-label="cancelled" />
    default:
      return (
        <Popup
          content={props.status}
          trigger={<Icon name="question circle" size="large" color="orange" aria-label={props.status} />}
        />
      )
  }
}
