import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { addTicketToFixVersion } from '../../actions/Jira'

export const useAddTicketToFixVersionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (releaseNote: { componentName: string; version: string; ticketId: string }) =>
      addTicketToFixVersion(releaseNote.componentName, releaseNote.version, [releaseNote.ticketId]),
    onSuccess: async (_, releaseNote) => {
      await queryClient.invalidateQueries(['jiraTickets', releaseNote.componentName, releaseNote.version])
    },
    onError: (error: Error) => {
      createErrorToast(`Adding ticket to release note failed ${error}`)
      return []
    }
  })
}
