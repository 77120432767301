import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { createReleaseNote, INewReleaseNote } from '../../actions/ReleaseNotes'

export const useCreateReleaseNote = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (releaseNote: INewReleaseNote) => createReleaseNote(releaseNote),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['releaseNotes'])
    },
    onError: (error: Error) => {
      createErrorToast(`Creating release note failed ${error}`)
      return []
    }
  })
}
