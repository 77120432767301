import { Message } from 'semantic-ui-react'
import { VendorClaim } from '../../../../actions/Utils'
import { useGetUserListQuery } from '../../../../queries/GetUserListQuery'
import { useGetUserSummaryQuery } from '../../../../queries/GetUserSummaryQuery'

export const useUserListService = (tenantId?: string, teamApi?: string, active?: boolean, hasAnyRoles?: boolean) =>
  useGetUserListQuery(tenantId, teamApi, active, hasAnyRoles)

export const useUserSummaryService = (tenantIds?: string[], teamApi?: string) =>
  useGetUserSummaryQuery(tenantIds, teamApi)

export const salesforceWarning = (customer: boolean, vendor?: VendorClaim) =>
  vendor === VendorClaim.Salesforce ? (
    <Message
      data-testid={'salesforce-warning'}
      header="Active Users"
      content={
        <div>
          <p>
            Note that this report counts an &quot;active&quot; user for
            {customer ? ' Salesforce teams ' : ' this Salesforce team '} as a user who has an active Salesforce licence.
            They may not have a Skedulo licence assigned to them. Please ensure you check the Skedulo licence report in
            Salesforce as well to get an accurate count of active users.
          </p>
        </div>
      }
      icon="warning"
      color="blue"
    />
  ) : (
    <p>
      <em>A user becomes &quot;active&quot; as soon as they are created within the Skedulo Web App.</em>
    </p>
  )
