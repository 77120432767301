import { Link } from 'react-router-dom'
import { Row } from 'react-table'
import { CustomerConnectionWithTeamData } from '../../../actions/CustomerConnections'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { formatDate } from '../../../utils/dateUtils'

export const CustomerLoginOptionsList = ({
  data,
  loading
}: {
  data?: CustomerConnectionWithTeamData[]
  loading?: boolean
}) => {
  const loginOptionColumnIdCell = ({ row }: { row: Row<CustomerConnectionWithTeamData> }) => (
    <Link to={`/customers/${row.original.customerId}/login-options/${row.original.id}`}>{row.original.id}</Link>
  )

  const loginOptionsColumns = createMemoizedColumns<CustomerConnectionWithTeamData>([
    {
      Header: 'ID',
      accessor: 'id',
      Cell: loginOptionColumnIdCell
    },
    {
      Header: 'Description',
      accessor: 'displayName'
    },
    {
      Header: 'Type',
      accessor: 'type'
    },
    {
      Header: 'Total teams used',
      accessor: 'teams',
      Cell: ({ row }: { row: Row<CustomerConnectionWithTeamData> }) => row.original.teams.length
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: ({ row }: { row: Row<CustomerConnectionWithTeamData> }) =>
        formatDate(row.original.createdAt, 'h:mma ddd D MMM YYYY')
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ row }: { row: Row<CustomerConnectionWithTeamData> }) =>
        formatDate(row.original.updatedAt, 'h:mma ddd D MMM YYYY')
    }
  ])

  return (
    <Table
      loading={loading}
      columns={loginOptionsColumns}
      data={data || []}
      emptyMessage="No login options found"
      color="blue"
      className="sticky-table"
      textAlign="left"
    />
  )
}
