import * as _ from 'lodash'
import * as React from 'react'
import { Button, DropdownProps, Grid, Header, InputProps, Modal } from 'semantic-ui-react'
import { DeliveryCheckTemplate } from '../../../actions/DeliveryCheck'
import { SearchableSelector } from '../../formComponents/Selectors'
import { deleteReleaseApplication, patchReleaseApplication } from '../../../actions/Admin'
import { getReleaseApplication, IReleaseApplication } from '../../../actions/Releases'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'
import { DetailsCheckbox, DetailsInput } from '../../formComponents/Inputs'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'
import { DeleteModal } from '../../modalComponents/DeleteModal'

export interface IProps {
  editable: boolean
  applicationName?: string
  deliveryCheckTemplates: DeliveryCheckTemplate[]
  open: boolean
  toggleOpen: () => void
  toggleEditable: () => void
  updateTemplates: () => void
}

export interface IState {
  application?: IReleaseApplication
  loading: boolean
  editedApplication?: IReleaseApplication
  editing: boolean
}

export class ReleaseApplicationsDetailsModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      loading: false,
      editing: false
    }
  }

  async componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.applicationName !== this.props.applicationName) {
      if (this.props.applicationName) {
        await this.getApplication(this.props.applicationName)
      } else {
        this.setState({ application: undefined })
      }
    }
  }

  getApplication = async (name: string) => {
    try {
      this.setState({ loading: true, application: undefined })
      const application = await getReleaseApplication(name)
      this.setState({
        application,
        editedApplication: application,
        loading: false
      })
    } catch (error) {
      createErrorToast(error)
      this.setState({ loading: false })
    }
  }

  onChange = (_ev: React.SyntheticEvent, data: InputProps) => {
    this.setState(existing => ({
      editedApplication: { ...existing.editedApplication, [data.name]: data.value } as IReleaseApplication
    }))
  }

  toggleNormalRelease = () => {
    if (this.state.editedApplication) {
      this.setState(existing => ({
        editedApplication: {
          ...existing.editedApplication,
          normalRelease: !existing.editedApplication?.normalRelease
        } as IReleaseApplication
      }))
    }
  }

  isDifferent = () => !_.matches(this.state.application)(this.state.editedApplication)

  submitEdit = async () => {
    const { application, editedApplication } = this.state
    if (this.isDifferent() && editedApplication && application) {
      try {
        this.setState({ editing: true })
        const patchApplication = {} as Partial<IReleaseApplication>
        for (const [key, value] of Object.entries(editedApplication)) {
          const releaseApplicationKey = key as keyof IReleaseApplication
          if (editedApplication[releaseApplicationKey] !== application[releaseApplicationKey]) {
            patchApplication[releaseApplicationKey] = value
          }
        }
        await patchReleaseApplication(application.applicationName, patchApplication)
        this.setState({ editing: false })
        createSuccessToast(`Application ${application?.applicationName} updated successfully.`)
        this.props.toggleOpen()
        this.props.updateTemplates()
      } catch (error) {
        this.setState({ editing: false })
        createErrorToast(error)
      }
    }
  }

  deleteApplication = async () => {
    if (this.state.application) {
      try {
        await deleteReleaseApplication(this.state.application.applicationName)
      } catch (error) {
        createErrorToast(error)
      }
    }
  }

  handleDelete = () => {
    this.props.updateTemplates()
    this.props.toggleOpen()
  }

  changeDeliveryCheckTemplate = (event: React.SyntheticEvent, data: DropdownProps) => {
    this.setState(existing => ({
      editedApplication: {
        ...existing.editedApplication,
        deliveryCheckTemplateId: data.value === '' ? null : data.value
      } as IReleaseApplication
    }))
  }

  render() {
    const { open, toggleOpen, editable } = this.props
    const { application, loading, editedApplication, editing } = this.state
    const deliveryCheckTemplateOptions = [
      { text: 'None', value: '' },
      ...this.props.deliveryCheckTemplates.map(template => ({
        text: template.name,
        value: template.id
      }))
    ]
    return (
      <Modal open={open} closeIcon onClose={toggleOpen} size="tiny">
        <Modal.Header>Release Application</Modal.Header>
        <Modal.Content scrolling>
          <LoaderWrap loading={loading}>
            <Grid stackable textAlign="center">
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Header size="tiny">Name</Header>
                  <p>{application?.applicationName}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <DetailsInput
                    editable={editable}
                    value={application?.component}
                    name="component"
                    onChange={this.onChange}
                    editedValue={editedApplication?.component}
                  />
                  <DetailsInput
                    editable={editable}
                    value={application?.repositoryName}
                    name="repositoryName"
                    onChange={this.onChange}
                    editedValue={editedApplication?.repositoryName}
                  />
                </Grid.Column>
                <Grid.Column>
                  <DetailsCheckbox
                    editable={editable}
                    checked={editable ? editedApplication?.normalRelease : application?.normalRelease}
                    title="Normal Release"
                    onClick={this.toggleNormalRelease}
                  />
                  <DetailsInput
                    editable={editable}
                    value={application?.repositoryOwner}
                    name="repositoryOwner"
                    onChange={this.onChange}
                    editedValue={editedApplication?.repositoryOwner}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <SearchableSelector
                  name="deliveryCheckTemplateId"
                  options={deliveryCheckTemplateOptions}
                  value={editedApplication?.deliveryCheckTemplateId ?? ''}
                  onChange={this.changeDeliveryCheckTemplate}
                  label="Delivery Check Template"
                  disabled={!editable}
                />
              </Grid.Row>
            </Grid>
          </LoaderWrap>
        </Modal.Content>
        <Modal.Actions>
          {editable ? (
            <Button
              color="blue"
              content="Save"
              loading={editing}
              disabled={editing || !this.isDifferent()}
              onClick={this.submitEdit}
            />
          ) : (
            <DeleteModal
              deleteMethod={this.deleteApplication}
              updateData={this.handleDelete}
              content={`Are you sure you want to delete the application ${application?.applicationName}`}
              type="Application"
              buttonText="Delete"
              disabled={editing || loading || !application}
            />
          )}
          <Button
            color={editable ? 'grey' : 'blue'}
            content={editable ? 'Cancel' : 'Edit'}
            disabled={!application || loading || editing}
            loading={loading}
            onClick={this.props.toggleEditable}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
