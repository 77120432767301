import { Column, Row } from 'react-table'
import { Grid, Header, Dropdown, Label, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { TeamConnection } from '../../actions/NewTeamConnections'
import { LoginOptionsService, TeamLoginTableUtil } from '../../views/newTeams/services/LoginOptionsService'
import EnableConnectionOnTeamModal from '../../views/newTeams/component/EnableConnectionOnTeamModal'
import DisableConnectionOnTeamModal from '../../views/newTeams/component/DisableConnectionOnTeamModal'
import HeaderWithControl from './BaseTableComponents/HeaderWithControl'
import { Table } from './ReactTable'

export interface TableTitleDesc {
  title: string
  description: string
  empty_message: string
}

export const SKEDULO_LOGIN_OPTION: TableTitleDesc = {
  title: 'Skedulo login options',
  description:
    'Skedulo global connection is used by Skedulo internal staff to log in to a new team in order to run onboarding.',
  empty_message: 'Skedulo login options not found'
}

export const CUSTOMER_LOGIN_OPTION: TableTitleDesc = {
  title: 'Customer login options',
  description:
    'Enable or disable available login options for this team. Each of the enabled options will be shown on the login screen for the members of this team. To add new login options or remove existing ones head to the customer details page.',
  empty_message: 'Customer login options not found'
}

const getStatusLabel = (isEnabled: boolean) =>
  isEnabled ? <Label color="green">Enabled</Label> : <Label>Disabled</Label>

const getTeamConnectionColumns = (tableUtil: TeamLoginTableUtil, customerId?: string): Column<TeamConnection>[] => {
  const { enable, disable } = tableUtil

  return [
    {
      Header: 'Description',
      accessor: 'displayName',
      Cell: ({ row }: { row: Row<TeamConnection> }) =>
        customerId ? (
          <Link to={`/customers/${customerId}/login-options/${row.original.connectionId}`}>
            {row.original.displayName}
          </Link>
        ) : (
          row.original.displayName
        )
    },
    { Header: 'ConnectionId', accessor: 'connectionId' },
    { Header: 'Type', accessor: 'type' },
    {
      Header: 'Status',
      accessor: 'enabled',
      Cell: ({ row }: { row: Row<TeamConnection> }) => getStatusLabel(row.original.enabled)
    },
    {
      Header: <div style={{ textAlign: 'right' }}>Action</div>,
      accessor: 'action',
      Cell: ({ row }: { row: Row<TeamConnection> }) => (
        <div style={{ textAlign: 'right' }}>
          <Dropdown icon={<Icon name="ellipsis vertical" className="bordered" />}>
            <Dropdown.Menu>
              {row.original.enabled ? (
                <DisableConnectionOnTeamModal
                  connectionId={row.original.connectionId}
                  displayName={row.original.displayName}
                  disableConnection={disable.sendRequest}
                />
              ) : (
                <EnableConnectionOnTeamModal
                  connectionId={row.original.connectionId}
                  displayName={row.original.displayName}
                  enableConnection={enable.sendRequest}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )
    }
  ]
}

const LoginOptionsTable = ({
  skeduloLoginOptionsData,
  customerLoginOptionsData,
  isLoading,
  isUpdating,
  skeduloTeamLoginTableUtil,
  customerTeamLoginTableUtil,
  customerId
}: LoginOptionsService) => {
  const columns_skedulo: Column<TeamConnection>[] = getTeamConnectionColumns(skeduloTeamLoginTableUtil)
  const columns_customer: Column<TeamConnection>[] = getTeamConnectionColumns(customerTeamLoginTableUtil, customerId)

  return (
    <div data-testid="login-options">
      <HeaderWithControl title="" size="h3" />
      <Grid>
        <Grid.Column textAlign="left" verticalAlign="middle">
          <Header as="h3">{SKEDULO_LOGIN_OPTION.title}</Header>
          {SKEDULO_LOGIN_OPTION.description}
        </Grid.Column>
      </Grid>
      <Table<TeamConnection>
        columns={columns_skedulo}
        data={skeduloLoginOptionsData}
        loading={isLoading || isUpdating}
        emptyMessage={SKEDULO_LOGIN_OPTION.empty_message}
        color="blue"
        textAlign="left"
      />

      <br />

      <Grid>
        <Grid.Column textAlign="left" verticalAlign="middle">
          <Header as="h3">{CUSTOMER_LOGIN_OPTION.title}</Header>
          {CUSTOMER_LOGIN_OPTION.description}
        </Grid.Column>
      </Grid>
      <Table<TeamConnection>
        columns={columns_customer}
        data={customerLoginOptionsData}
        loading={isLoading || isUpdating}
        emptyMessage={CUSTOMER_LOGIN_OPTION.empty_message}
        color="blue"
        textAlign="left"
      />
    </div>
  )
}

export default LoginOptionsTable
