// Login Option Type
export type LoginOptionType = 'Email and Password' | 'SSO (single sign-on)' | undefined
export const EMAIL_PASSWORD = 'Email and Password'
export const EMAIL_PASSWORD_GUIDE =
  'Requires users to enter their email and password to authenticate and access the application'
export const SSO = 'SSO (single sign-on)'
export const SSO_GUIDE = 'Allows users to log in to multiple applications using a single set of credentials'
export const SELECT_SSO_MESSAGE = 'Select the SSO provider below'
export const SELECT_LOGIN_TYPE_MESSAGE = 'Select a login type'
export const SCOPES_INSTRUCTION = 'Must be separated by a space.'

// SSO Options
export type SsoType =
  | 'Google Workspace'
  | 'Okta Workforce'
  | 'Open ID Connect'
  | 'SAML'
  | 'Microsoft Azure AD'
  | undefined
export const GOOGLE_WORKSPACE = 'Google Workspace'
export const OKTA_WORKFORCE = 'Okta Workforce'
export const OPEN_ID_CONNECT = 'Open ID Connect'
export const SAML_AUTH = 'SAML'
export const MICROSOFT_AZURE_AD = 'Microsoft Azure AD'

// Login type mapping for API request
export const CUSTOMER_LOGIN_TYPE_REQUEST = {
  'Email and Password': 'database',
  'Google Workspace': 'google',
  'Okta Workforce': 'okta',
  'Open ID Connect': 'oidc',
  // eslint-disable-next-line
  SAML: 'samlType',
  'Microsoft Azure AD': 'azure'
}

// 2 step title for UI
export const STEP_LOGIN_OPTION_TYPE = 'Login option type'
export const STEP_LOGIN_OPTION_DETAILS = 'Login option details'
