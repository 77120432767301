import { Icon, Label, Popup, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react'
import { DeliveryCheckStatus } from '../../../actions/DeliveryCheck'

interface StatusIconProps {
  statusName: string
  labelColor: SemanticCOLORS
  iconName: SemanticICONS
}

type Status = DeliveryCheckStatus | 'succeeded' | 'running' | 'missing'

const statusIconPropsByStatus: { [key in Status]: StatusIconProps } = {
  expired: {
    statusName: 'Expired',
    labelColor: 'grey',
    iconName: 'clock'
  },
  failed: {
    statusName: 'Failed',
    labelColor: 'red',
    iconName: 'times circle'
  },
  created: {
    statusName: 'Pending',
    labelColor: 'yellow',
    iconName: 'clock'
  },
  warning: {
    statusName: 'Warning',
    labelColor: 'orange',
    iconName: 'exclamation circle'
  },
  passed: {
    statusName: 'Passed',
    labelColor: 'green',
    iconName: 'check circle'
  },
  succeeded: {
    statusName: 'Successful',
    labelColor: 'green',
    iconName: 'check circle'
  },
  running: {
    statusName: 'In Progress',
    labelColor: 'yellow',
    iconName: 'clock'
  },
  missing: {
    statusName: 'Missing',
    labelColor: 'orange',
    iconName: 'question circle'
  }
}

export const DeliveryCheckStatusIcon = (props: { status: Status; expired: boolean; id: string }) => {
  const derivedStatus = props.expired ? 'expired' : props.status
  const iconProps = statusIconPropsByStatus[derivedStatus]
  return (
    <Popup
      trigger={<Icon name={iconProps.iconName} color={iconProps.labelColor} size="large" aria-label={derivedStatus} />}
      content={iconProps.statusName}
      basic
      key={props.id}
    />
  )
}

export const StatusCount = (props: { status: Status; count: number }) => {
  const iconProps = statusIconPropsByStatus[props.status]

  return props.count > 0 ? (
    <Label size="large" color={iconProps.labelColor}>
      <Icon name={iconProps.iconName} />
      {`${props.count.toString()} ${iconProps.statusName}`}
    </Label>
  ) : null
}
