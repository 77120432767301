import { User } from '@auth0/auth0-spa-js'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Divider, Dropdown, Grid, Icon, Image, Label, Menu } from 'semantic-ui-react'
import { lowerCase, toUpper } from 'lodash'
import { useRouterProps } from '../../router/RouterProps'
import AuthClient from '../../auth/AuthClient'
import { OldModelWarning } from '../banners/OldModelWarning'

export const getLogoForPath = (currentSite: string) => {
  switch (currentSite) {
    case 'applications':
      return <Image id="header-logo" inline src={require('../../images/application-management-logo.svg')} />
    default:
      return <Image id="header-logo" inline src={require('../../images/admin-console-logo.svg')} />
  }
}

export const getEnvLabelForHost = (hostname: string) => {
  switch (hostname) {
    case 'admin-console.skedulo.com':
      return <></>
    case 'staging-admin-console.test.skl.io':
      return (
        <Label id="env-label" color="blue">
          STAGING
        </Label>
      )
    default:
      return (
        <Label id="env-label" color="orange">
          TEST
        </Label>
      )
  }
}

export const getColorScheme = (currentSite: string, path: string) => {
  switch (currentSite) {
    case 'applications':
      return { '--bg': '#223049', '--text': '#ffffff' }
    case 'old-model':
      return { '--bg': '#f3f5f9', '--text': '#223049' }
    default:
      return { '--bg': '#ffffff', '--text': '#223049', '--border': `${path !== '' && '1px solid #cacfd9'}` }
  }
}

const menuItemsHome: string[] = ['customers', 'teams', 'feature-flag-report', 'packages']
const menuItemsConsole: string[] = ['applications', 'deployments', 'releases', 'deliveries', 'mobile-releases']
const menuItemsLegacy: string[] = ['customers', 'teams', 'tenants', 'feature-flag-report', 'users-report']

const Header = () => {
  const props = useRouterProps()
  const pathname = props.location.pathname.split('/')
  const currentSite = pathname[1] !== 'old-model' && pathname[1] !== 'applications' ? '' : pathname[1]

  const [activeItem, setActiveItem] = useState('')
  const [user, setUser] = useState(undefined as User | undefined)
  const userInitials = `${user?.given_name?.charAt(0)}${user?.family_name?.charAt(0)}`

  const clearActive = (): void => {
    setActiveItem('')
  }

  useEffect(() => {
    const getUser = async () => {
      const fetchedUser = await AuthClient.getUser()
      setUser(fetchedUser)
    }

    getUser()
  }, [])

  useEffect(() => {
    if (currentSite === '') {
      setActiveItem(pathname[1])
    } else {
      setActiveItem(pathname[2])
    }
  }, [pathname])

  const logout = () => {
    // Only valid logout URLs in auth0 tenant are allowed.
    AuthClient.logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const sentenceCase = (item: string) => toUpper(item.charAt(0)) + lowerCase(item.slice(1))

  const getMenuItems = () => {
    switch (currentSite) {
      case 'old-model':
        return (
          <>
            {menuItemsLegacy.map(item => (
              <Menu.Item key={item} as={Link} to={'/old-model/' + item} name={item} active={activeItem === item}>
                {sentenceCase(item)}
              </Menu.Item>
            ))}
          </>
        )
      case 'applications':
        return (
          <>
            {menuItemsConsole.map(item => (
              <Menu.Item key={item} as={Link} to={'/applications/' + item} name={item} active={activeItem === item}>
                {sentenceCase(item)}
              </Menu.Item>
            ))}
          </>
        )
      default:
        return (
          <>
            {menuItemsHome.map(item => (
              <Menu.Item key={item} as={Link} to={'/' + item} name={item} active={activeItem === item}>
                {sentenceCase(item)}
              </Menu.Item>
            ))}
          </>
        )
    }
  }

  const getSettingsItems = () => {
    const detailsItem = (
      <Dropdown.Item as={Link} to={(currentSite && '/') + currentSite + '/my-details'} onClick={clearActive}>
        <Icon name="user" />
        My details
      </Dropdown.Item>
    )
    const logoutItem = (
      <Dropdown.Item onClick={logout}>
        <Icon name="log out" />
        Log out
      </Dropdown.Item>
    )
    const homeItem = (target: string) => (
      <Dropdown.Item as={Link} to="" target={target} onClick={clearActive}>
        <Icon name="external alternate" />
        Admin console home
      </Dropdown.Item>
    )
    const appManagementItem = (
      <Dropdown.Item as={Link} to="/applications" target="_blank" onClick={clearActive}>
        <Icon name="external alternate" />
        Application management
      </Dropdown.Item>
    )
    switch (currentSite) {
      case 'old-model':
        return (
          <>
            {detailsItem}
            <Divider />
            {homeItem('')}
            <Divider />
            {appManagementItem}
            <Divider />
            {logoutItem}
          </>
        )
      case 'applications':
        return (
          <>
            {detailsItem}
            <Divider />
            {homeItem('_blank')}
            <Divider />
            {logoutItem}
          </>
        )
      default:
        return (
          <>
            {detailsItem}
            <Divider />
            <Dropdown.Item as={Link} to="/old-model" onClick={clearActive}>
              <Icon name="external alternate" />
              Legacy customer model
            </Dropdown.Item>
            <Divider />
            {appManagementItem}
            <Divider />
            {logoutItem}
          </>
        )
    }
  }

  return (
    <>
      <Grid id="header" verticalAlign="middle" centered stackable style={getColorScheme(currentSite, pathname[1])}>
        {/* LOGO & ENV */}
        <Grid.Column id="header-col-1" width={4}>
          <Link to={'/' + currentSite} onClick={clearActive}>
            {getLogoForPath(currentSite)}
          </Link>
          {getEnvLabelForHost(window.location.hostname)}
        </Grid.Column>

        {/* MENU ITEMS */}
        <Grid.Column id="header-col-2" width={8}>
          <Menu id="header-menu" size="huge" stackable>
            {getMenuItems()}
          </Menu>
        </Grid.Column>

        {/* SETTINGS */}
        <Grid.Column width={4} id="header-col-3">
          <Menu id="header-menu">
            {currentSite === 'applications' && (
              <>
                <Menu.Item>
                  <Button
                    data-testid="settings-button"
                    id="header-settings"
                    className="clickable"
                    icon="cog"
                    as={Link}
                    to="/applications/settings"
                  />
                </Menu.Item>
                <div id="settings-divider"></div>
              </>
            )}
            <Dropdown
              id="header-info-menu"
              data-testid="log-out-dropdown"
              trigger={
                <Label circular id="user-avatar">
                  {userInitials}
                </Label>
              }
              className={`${activeItem === 'header-info-menu' && 'active'} item`}
              direction="left"
              icon={null}
            >
              <Dropdown.Menu>{getSettingsItems()}</Dropdown.Menu>
            </Dropdown>
          </Menu>
        </Grid.Column>
      </Grid>
      {currentSite === 'old-model' && <OldModelWarning />}
    </>
  )
}

export default Header
