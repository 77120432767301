import * as React from 'react'
import { DropdownProps, Grid, Icon, InputOnChangeData, SearchProps, Segment } from 'semantic-ui-react'
import { DeliveryCheckTemplate, getDeliveryCheckTemplates } from '../../actions/DeliveryCheck'
import { getReleaseApplications, IReleaseApplicationDetails } from '../../actions/Admin'
import { createErrorToast } from '../alertComponents/Alert'
import { SearchInput } from '../searchComponents/SearchInput'
import { ISearch } from '../Utils'
import { CreateReleaseApplicationModal } from './components/CreateReleaseApplicationModal'
import { ReleaseApplicationsDetailsModal } from './components/ReleaseApplicationsDetailsModal'
import { ReleaseApplicationsList } from './components/ReleaseApplicationsList'

export interface IState {
  releaseApplications: IReleaseApplicationDetails[]
  deliveryCheckTemplates: DeliveryCheckTemplate[]
  loading: boolean
  searchValue: ISearch
  modalApplication?: string
  modalOpen: boolean
  modalEditable: boolean
}

export class ReleaseApplications extends React.PureComponent<object, IState> {
  constructor(props: object) {
    super(props)
    this.state = {
      loading: false,
      releaseApplications: [],
      deliveryCheckTemplates: [],
      searchValue: { searchCategory: 'all', searchValue: '' },
      modalOpen: false,
      modalEditable: false
    }
  }

  async componentDidMount() {
    await Promise.all([this.fetchReleaseApplications(), this.fetchDeliveryCheckTemplates()])
  }

  fetchReleaseApplications = async () => {
    try {
      this.setState({ loading: true })
      const releaseApplications = await getReleaseApplications()
      this.setState({ loading: false, releaseApplications })
    } catch (error) {
      this.setState({ loading: false })
      createErrorToast(error)
    }
  }

  fetchDeliveryCheckTemplates = async () => {
    try {
      const deliveryCheckTemplates = await getDeliveryCheckTemplates()
      this.setState({ deliveryCheckTemplates })
    } catch (error) {
      createErrorToast(error)
    }
  }

  handleSearchChange: SearchProps['onChange'] = (event: React.ChangeEvent, data: InputOnChangeData) => {
    this.setState(prevState => ({
      searchValue: { searchValue: data.value, searchCategory: prevState.searchValue.searchCategory }
    }))
  }

  handleSearchCategoryChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    this.setState(prevState => ({
      searchValue: { searchValue: prevState.searchValue.searchValue, searchCategory: data.value as string }
    }))
  }

  clearTemplateModal = () => {
    this.setState({ modalOpen: false, modalApplication: undefined, modalEditable: false })
  }

  toggleEditable = () => this.setState(prevState => ({ modalEditable: !prevState.modalEditable }))

  createTemplateDetailsLink = (name: string) => () => {
    this.setState({ modalOpen: true, modalApplication: name })
  }

  render() {
    const { loading, searchValue, releaseApplications, modalOpen, modalApplication, modalEditable } = this.state
    const searchOptions = [
      { text: 'All', value: 'all' },
      { text: 'Name', value: 'applicationName' },
      { text: 'Component', value: 'component' },
      { text: 'Repository Owner', value: 'repositoryOwner' },
      { text: 'Repository Name', value: 'repositoryName' }
    ]
    return (
      <Segment color="blue" className="scrollable">
        <Grid stackable verticalAlign="middle">
          <Grid.Column width={6}>
            <h3>Releasable Applications</h3>
          </Grid.Column>
          <Grid.Column textAlign="right" width={10}>
            <Icon
              size="large"
              name="refresh"
              onClick={this.fetchReleaseApplications}
              loading={loading}
              className="clickable"
            />
            <SearchInput
              searchValue={searchValue}
              searchOptions={searchOptions}
              handleSearchChange={this.handleSearchChange}
              handleSearchCategoryChange={this.handleSearchCategoryChange}
            />
            <CreateReleaseApplicationModal updateApplications={this.fetchReleaseApplications} />
          </Grid.Column>
        </Grid>
        <ReleaseApplicationsDetailsModal
          open={modalOpen}
          toggleOpen={this.clearTemplateModal}
          applicationName={modalApplication}
          deliveryCheckTemplates={this.state.deliveryCheckTemplates}
          updateTemplates={this.fetchReleaseApplications}
          editable={modalEditable}
          toggleEditable={this.toggleEditable}
        />
        <ReleaseApplicationsList
          applications={releaseApplications}
          makeDetailsLink={this.createTemplateDetailsLink}
          searchValue={searchValue}
          loading={loading}
        />
      </Segment>
    )
  }
}
