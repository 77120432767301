import { Header, Label } from 'semantic-ui-react'
import first from 'lodash/first'
import { Row } from 'react-table'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { useMobileV2FormService } from '../services/MobileV2FormService'
import { FormDeployContextEnum, IMobileV2Form } from '../../../actions/MobileV2Forms'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'

enum MobileFormV2Status {
  Draft = 'Draft',
  Published = 'Published'
}

enum MobileV2FormStatusColor {
  Green = 'green',
  Grey = 'grey'
}

enum MobileV2FormType {
  Job = 'Job',
  Resource = 'Resource'
}

const EMPTY_MESSAGE = 'No mobile v2 forms found.'

export const getFormName = (form: IMobileV2Form) => {
  const formDefinition = form.formRev?.definition
  const firstFormDefinitionItem = first(formDefinition?.forms)
  return firstFormDefinitionItem?.name ?? ''
}

const getFormJobTypeList = (form: IMobileV2Form) => form.jobTypes ?? []

const getFormStatus = (form: IMobileV2Form) => {
  const hasJobTypes = getFormJobTypeList(form)?.length > 0
  return hasJobTypes ? MobileFormV2Status.Published : MobileFormV2Status.Draft
}

const getFormType = (form: IMobileV2Form) => {
  const formDefinition = form.formRev?.definition
  const deployContext = formDefinition?.deploy?.context
  return deployContext === FormDeployContextEnum.Resource ? MobileV2FormType.Resource : MobileV2FormType.Job
}

const getJobTypeList = (form: IMobileV2Form) => getFormJobTypeList(form)?.join(', ')

interface MobileFormListProps {
  teamId: string
}

const MobileFormList = ({ teamId }: MobileFormListProps) => {
  const { data: teamData, isLoading: loadingTeamData } = useGetTeamQuery(teamId)
  const { mobileV2Forms, isLoading: loadingMobileFormList } = useMobileV2FormService({
    tenantId: teamData?.tenantId ?? '',
    region: teamData?.region ?? ''
  })

  const tableColumns = createMemoizedColumns<IMobileV2Form>(
    [
      {
        Header: 'Form name',
        accessor: getFormName,
        width: 100
      },
      {
        Header: 'Form type',
        accessor: getFormType,
        width: 100
      },
      {
        Header: 'Job type',
        accessor: getJobTypeList
      },
      {
        Header: 'Status',
        accessor: getFormStatus,
        Cell: ({ row }: { row: Row<IMobileV2Form> }) => {
          const status = getFormStatus(row.original)
          const statusColor =
            status === MobileFormV2Status.Published ? MobileV2FormStatusColor.Green : MobileV2FormStatusColor.Grey
          return (
            <Label basic color={statusColor}>
              {status}
            </Label>
          )
        }
      }
    ],
    []
  )

  return (
    <div data-testid="mobile-v2-forms">
      <Header as="h3">Mobile v2 forms</Header>
      <Table<IMobileV2Form>
        columns={tableColumns}
        data={mobileV2Forms}
        loading={loadingTeamData || loadingMobileFormList}
        emptyMessage={EMPTY_MESSAGE}
        color="blue"
        celled
      />
    </div>
  )
}

export default MobileFormList
