import { Menu, Breadcrumb, Icon, Grid, Divider, Header } from 'semantic-ui-react'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { IJiraTicket, IReleaseNoteInfo } from '../../actions/ReleaseNotes'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { createErrorToast } from '../alertComponents/Alert'
import { DeliveriesList } from '../deliveries/Components/DeliveriesList'
import { useGetReleaseNoteQuery } from '../../queries/releaseNotes/GetReleaseNotesQuery'
import { useDeliveriesForReleaseNotesQuery } from '../../queries/releaseNotes/GetDeliveriesForReleaseNoteQuery'
import { useValidateReleaseNotesQuery } from '../../queries/releaseNotes/GetJiraTicketsForReleaseNotesQuery'
import { useAddTicketToFixVersionMutation } from '../../mutations/releaseNotes/AddTicketToFixVersion'
import { ReleaseNotesTicketsList } from './Components/ReleaseNotesTicketList'
import { ReleaseNoteInformation } from './Components/ReleaseNoteInformation'
import { CompleteReleaseNoteModal } from './Components/CompleteReleaseNoteModal'

const title = 'Release Note Details'

export const ReleaseNoteDetails = () => {
  const params = useParams()
  const navigate = useNavigate()
  useDocumentTitle(title)

  const componentName = params.componentName!
  const version = params.version!

  const { data: releaseNote, error: releaseNoteError } = useGetReleaseNoteQuery(componentName, version)
  const { data: deliveries, status: deliveriesStatus } = useDeliveriesForReleaseNotesQuery(componentName, version)
  const { data: validation, status: validationStatus } = useValidateReleaseNotesQuery(componentName, version)
  const { mutate: addTicketToFixVersion } = useAddTicketToFixVersionMutation()

  const addTicketToFixVersionHook = async (ticket: IJiraTicket) => {
    if (releaseNote) {
      addTicketToFixVersion({
        componentName: releaseNote.db.componentName,
        version: releaseNote.db.releaseVersion,
        ticketId: ticket.id
      })
    }
  }

  const releaseNoteCheck = () => true

  useEffect(() => {
    if (releaseNoteError) {
      createErrorToast(releaseNoteError)
      navigate('/applications/deliveries')
    }
  }, [releaseNoteError, navigate])

  return (
    <div className="route-component">
      <span>
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={() => navigate(-1)}>
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Menu.Item>
              <CompleteReleaseNoteModal
                releaseNoteInfo={releaseNote}
                disabled={!releaseNote || releaseNote?.db?.released}
                check={!releaseNoteCheck()}
                checkMessage={[]}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid columns={2} stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={5} style={{ height: '100%' }}>
            <ReleaseNoteInformation releaseNote={releaseNote ?? ({} as IReleaseNoteInfo)} />
          </Grid.Column>
          <Grid.Column width={11} style={{ height: '100%' }} className="scrollable-no-margin">
            {/* Release Note Tickets */}
            <Header as="h3">Tickets</Header>
            <ReleaseNotesTicketsList
              tickets={validation?.jiraTickets || []}
              isLoading={validationStatus === 'loading'}
              releaseNotes={releaseNote}
              addTicketToFixVersionHook={addTicketToFixVersionHook}
            />
            <Divider />
            {/* Release Note Deliveries */}
            <Header as="h3">Deliveries</Header>
            <DeliveriesList
              deliveries={deliveries || []}
              filterCategories={new Map()}
              loading={deliveriesStatus === 'loading'}
            />
          </Grid.Column>
        </Grid>
      </span>
    </div>
  )
}

ReleaseNoteDetails.whyDidYouRender = true
