import { adminApi } from './Endpoints'

export interface IReleaseNote {
  id: string
  componentName: string
  released: boolean
  releaseVersion: string
  baseVersion: string
}

export interface INewReleaseNote {
  componentName: string
  releaseVersion: string
  releaseDate: Date
  baseVersion: string
  noJira?: boolean
}

export interface IUpdateableReleaseNoteFields {
  // This should only contain fields that can be updated safely
  releaseDate: Date
  baseVersion: string
  released: boolean
  noJira?: boolean
}

export interface JiraRelease {
  self: string
  id: string
  name: string
  archived: boolean
  released: boolean
  releaseDate?: string
  userReleaseDate?: string
  projectId: number
}

export interface IReleaseNoteInfo {
  db: IReleaseNote
  jira?: JiraRelease
}

interface IssueType {
  name: string
  iconUrl: string
}

export interface IJiraTicket {
  id: string
  status: string
  addedToRelease: boolean
  title: string
  releaseNotesExist: boolean
  releaseNotesRequired: string
  deployedInReleaseNoteDelivery: boolean
  issuetype: IssueType
}

export interface DeliveryVersion {
  version: string
  tag: string
  repositoryOwner: string
  repositoryName: string
}

export interface DeliveryVersionCompare {
  head: DeliveryVersion
  base: DeliveryVersion
  tickets: string[]
}

export interface ReleaseValidation {
  jiraTickets: IJiraTicket[]
  applicationVersions: Record<string, DeliveryVersionCompare>
}

// Get Release Notes
export const getReleaseNotes = () =>
  adminApi
    .get<IReleaseNote[]>('/releasenotes')
    .then(response => response.data.sort((a, b) => b.releaseVersion.localeCompare(a.releaseVersion)))

// Get Release Note Details
export const getReleaseNoteDetails = (component: string, version: string) =>
  adminApi.get<IReleaseNoteInfo>(`/releasenotes/${component}/${version}`).then(response => response.data)

// Create Release Note
export const createReleaseNote = (newReleaseNotes: INewReleaseNote) =>
  adminApi.post('/releasenotes', newReleaseNotes).then(response => response.data)

// Update Release Note
export const updateReleaseNote = (
  component: string,
  version: string,
  updateFields: Partial<IUpdateableReleaseNoteFields>
) => adminApi.post(`/releasenotes/${component}/${version}`).then(response => response.data)

// Validate Release Notes
export const validateReleaseNotes = (component: string, version: string) =>
  adminApi.get<ReleaseValidation>(`/releasenotes/${component}/${version}/validate`).then(response => response.data)

// Get Release Note Deliveries
export const getDeliveriesForReleaseNotes = (component: string, version: string) =>
  adminApi.get(`/releasenotes/${component}/${version}/deliveries`).then(response => response.data)

// Complete Release Note
export const completeReleaseNote = (component: string, version: string) =>
  adminApi.post(`/releasenotes/${component}/${version}/complete`).then(response => response.data)
