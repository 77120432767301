import * as React from 'react'
import { Button, Checkbox, Table } from 'semantic-ui-react'
import { IReleaseApplicationDetails } from '../../../actions/Admin'
import { IReleaseApplication } from '../../../actions/Releases'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { filterData, sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { ISearch } from '../../Utils'

interface IProps {
  applications: IReleaseApplicationDetails[]
  makeDetailsLink: (id: string) => () => void
  searchValue: ISearch
  loading: boolean
}

interface IState {
  sortColumn: string
  sortDirection: 'ascending' | 'descending'
}

export class ReleaseApplicationsList extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      sortColumn: 'applicationName',
      sortDirection: 'ascending'
    }
  }

  handleSort = (clickedColumn: string) => () => {
    const { sortColumn, sortDirection } = this.state
    if (sortColumn !== clickedColumn) {
      this.setState({
        sortColumn: clickedColumn,
        sortDirection: 'ascending'
      })
    } else {
      this.setState({
        sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending'
      })
    }
  }
  render() {
    const { makeDetailsLink, searchValue, applications } = this.props
    const { sortColumn, sortDirection } = this.state

    const SortedTemplateHeaderCell = SortedHeaderCell(sortColumn, sortDirection, this.handleSort)

    const filteredReleaseApplications: IReleaseApplication[] = filterData(searchValue, applications)
    const sortedReleaseApplications = sortData(filteredReleaseApplications, sortColumn, sortDirection)

    return (
      <Table striped color="blue" sortable selectable stackable size="small" celled>
        <Table.Header>
          <Table.Row>
            <SortedTemplateHeaderCell title="Name" />
            <SortedTemplateHeaderCell title="Component" />
            <SortedTemplateHeaderCell title="Repository Name" />
            <SortedTemplateHeaderCell title="Repository Owner" />
            <Table.HeaderCell textAlign="center" className="table-cell-overflow">
              Normal Release
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap
          loading={this.props.loading}
          array={sortedReleaseApplications}
          emptyMessage="No Deployment Templates"
        >
          <Table.Body>
            {sortedReleaseApplications.map(releaseApplication => (
              <Table.Row key={releaseApplication.name}>
                <Table.Cell className="table-cell-overflow">{releaseApplication.applicationName}</Table.Cell>
                <Table.Cell className="table-cell-overflow">{releaseApplication.component}</Table.Cell>
                <Table.Cell className="table-cell-overflow">{releaseApplication.repositoryName}</Table.Cell>
                <Table.Cell className="table-cell-overflow">{releaseApplication.repositoryOwner}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Checkbox checked={releaseApplication.normalRelease} disabled />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    icon="file alternate outline"
                    className="square-icon-button"
                    onClick={makeDetailsLink(releaseApplication.applicationName)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </TableLoaderWrap>
      </Table>
    )
  }
}
