import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import { useDebounce } from 'usehooks-ts'
import { DynamoQuery, DynamoQueryFilter, DynamoQueryResponse } from '../actions/Utils'
import { createErrorToast } from '../views/alertComponents/Alert'

export interface DynamoInfiniteQuery<T> {
  data?: InfiniteData<DynamoQueryResponse<T>>
  refetch: () => void
  isLoading: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  fetchNextPage: () => void
  hasNextPage?: boolean
}

export const DEFAULT_NUM_PER_PAGE = 20

export const useDynamoInfiniteQuery =
  <T extends any>(
    recordType: string,
    getRecordsFn: (query: DynamoQuery) => Promise<DynamoQueryResponse<T>>,
    numPerPage?: number,
    disabled?: boolean
  ) =>
  (queryFilter: DynamoQueryFilter, pageSize: number = numPerPage || DEFAULT_NUM_PER_PAGE): DynamoInfiniteQuery<T> => {
    // Only Update the query filter if it hasn't changed in 500ms
    // to prevent constant fetching while the user is typing their query
    const debouncedQueryFilter = useDebounce(queryFilter, 500)
    const { data, refetch, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
      [recordType, debouncedQueryFilter, pageSize],
      ({ pageParam = {} }) => getRecordsFn({ pageSize, ...queryFilter, ...pageParam }),
      {
        getNextPageParam: lastPage => {
          if (lastPage.next) {
            return { next: lastPage.next }
          }
          return undefined
        },
        retry: false,
        onError: (error: Error) => createErrorToast(error),
        enabled: disabled ? false : true
      }
    )
    return {
      data,
      refetch,
      isLoading,
      isFetching,
      isFetchingNextPage,
      fetchNextPage,
      hasNextPage
    }
  }
