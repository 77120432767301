import { Checkbox, Grid, Icon } from 'semantic-ui-react'
import { useQueryClient } from '@tanstack/react-query'
import { useLocalStorage } from 'usehooks-ts'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { IReleaseApplication } from '../../actions/Releases'
import { useDeliveriesQuery } from '../../queries/deliveries/GetDeliveriesQuery'
import { useReleaseApplicationsQuery } from '../../queries/releases/GetReleaseApplicationsQuery'
import { FilterCategoryOptions, Filters } from '../filterComponents/Filters'
import { FilterSearch } from '../filterComponents/FilterSearch'
import { DeliveriesList } from './Components/DeliveriesList'
import { CreateDeliveryModal } from './Components/CreateDeliveryModal'

const title = 'Deliveries'

export const Deliveries = () => {
  const [displayLocalTime, setDisplayLocalTime] = useLocalStorage('display-local-time', false)
  useDocumentTitle(title)
  const queryClient = useQueryClient()

  let newApplications: IReleaseApplication[] = []

  const { data: applications, status: applicationsStatus } = useReleaseApplicationsQuery()
  const { data: deliveries, status: deliveriesStatus } = useDeliveriesQuery()

  const filterCategories = new Map<string, FilterCategoryOptions>([
    ['version', { text: 'Version' }],
    ['applicationName', { text: 'Application' }],
    ['status', { text: 'Status', values: ['Approved', 'Pending', 'Rejected', 'Superseded'] }],
    ['tag', { text: 'Tag' }]
  ])

  if (applications) {
    newApplications = [...applications]
    newApplications.sort((a, b) => a.applicationName.localeCompare(b.applicationName))
    filterCategories.set('applicationName', {
      text: 'Application',
      values: newApplications.map(application => application.applicationName)
    })
  }

  const loading = applicationsStatus === 'loading' || deliveriesStatus === 'loading'

  const invalidateDeliveries = async () => {
    await queryClient.invalidateQueries(['deliveries'])
  }

  return (
    <div className="route-component">
      <Grid columns={16} verticalAlign="middle" stackable>
        <Grid.Column width={12}>
          <Grid verticalAlign="middle">
            <Grid.Column>
              <Icon
                size="large"
                name="refresh"
                onClick={invalidateDeliveries}
                loading={loading}
                className="clickable"
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign="left" verticalAlign="middle">
              <FilterSearch category="applicationName" />
            </Grid.Column>
            <Grid.Column width={10} textAlign="left" verticalAlign="middle">
              <Filters filterCategories={filterCategories} />
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column width={2}>
          <Checkbox
            label={<label htmlFor="displayLocalTime">Show local times</label>}
            id="displayLocalTime"
            toggle
            checked={displayLocalTime}
            onChange={(_, data) => {
              setDisplayLocalTime(!!data.checked)
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign="right" width={2}>
          <CreateDeliveryModal applications={newApplications} />
        </Grid.Column>
      </Grid>
      <DeliveriesList
        deliveries={deliveries}
        loading={loading}
        filterCategories={filterCategories}
        displayLocalTime={displayLocalTime}
      />
    </div>
  )
}
