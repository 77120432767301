/* eslint-disable react/jsx-no-bind */
import * as React from 'react'
import { Button, Checkbox, CheckboxProps, Header, Input, Segment, Select, Table } from 'semantic-ui-react'

import { createErrorToast } from '../../../alertComponents/Alert'
import { getBasePermissions, getUserPermissions, IPermissions, PermissionSet } from '../../../../actions/Permissions'
import { TableLoaderWrap } from '../../../tableComponents/TableLoader'
import { PermissionObject } from './PermissionObject'

const permissionSetOptions: { key: PermissionSet; text: string; value: PermissionSet }[] = [
  { key: 'Skedulo_API_User', text: 'Skedulo API User', value: 'Skedulo_API_User' },
  { key: 'Baseline_Permission_Set', text: 'Skedulo Base', value: 'Baseline_Permission_Set' },
  {
    key: 'Community_Resource_Permission_Set',
    text: 'Skedulo Community Resource',
    value: 'Community_Resource_Permission_Set'
  },
  { key: 'Resource_Permission_Set', text: 'Skedulo Resource', value: 'Resource_Permission_Set' },
  {
    key: 'Resource_Permission_Set_Without_Assigned_Apps',
    text: 'Skedulo Resource Without Assigned Apps',
    value: 'Resource_Permission_Set_Without_Assigned_Apps'
  },
  { key: 'Scheduler_Permission_Set', text: 'Skedulo Scheduler', value: 'Scheduler_Permission_Set' },
  { key: 'View_All_Permission_Set', text: 'Skedulo View All', value: 'View_All_Permission_Set' }
]

export interface IPermissionsProps {
  tenantId?: string
}

export const Permissions: React.FC<IPermissionsProps> = ({ tenantId }) => {
  const [basePermissions, setBasePermissions] = React.useState<IPermissions>({})
  const [userPermissions, setUserPermissions] = React.useState<IPermissions>({})
  const [loading, setLoading] = React.useState<boolean>(false)
  const [showAll, setShowAll] = React.useState<boolean>(false)
  const [userId, setUserId] = React.useState<string>('')
  const [permissionSet, setPermissionSet] = React.useState<PermissionSet>('Resource_Permission_Set')

  const fetchPermissions = async () => {
    if (userId.length === 0 || !tenantId) {
      return
    }

    setLoading(true)

    const basePermissionsPromise = getBasePermissions(tenantId, permissionSet)
    const userPermissionPromise = getUserPermissions(userId, tenantId)

    try {
      const [basePermissionsResponse, userPermissionsResponse] = await Promise.all([
        basePermissionsPromise,
        userPermissionPromise
      ])

      setBasePermissions(basePermissionsResponse)
      setUserPermissions(userPermissionsResponse)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      createErrorToast(error)
    }
  }

  const handleUserIdChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(event.target.value)
  }, [])

  const handlePermissionChange = React.useCallback((_event, data) => {
    setPermissionSet(data.value)
  }, [])

  const handleShowAllChange = React.useCallback(
    (_event: React.FormEvent<HTMLInputElement>, data?: CheckboxProps | undefined) => {
      setShowAll(data?.checked ? data.checked : false)
    },
    []
  )

  const renderPermissions = () =>
    Object.keys(basePermissions)
      .sort()
      .map(objectName => {
        const baseObject = basePermissions[objectName]
        const userObject = userPermissions[objectName]
        return (
          <PermissionObject
            key={`object:${objectName}`}
            objectName={objectName}
            baseObject={baseObject}
            userObject={userObject}
            showAll={showAll}
          />
        )
      })

  // For some reason the tenant data is optional, so we need to check in case it does not exist
  if (!tenantId) {
    return (
      <Header as="h4" textAlign="center" data-sk-name="no-tenant-id">
        No tenant ID
      </Header>
    )
  }

  return (
    <div data-sk-name="permissions">
      <Header as="h3">Permissions</Header>
      <Header size="tiny">User ID and permission set</Header>
      <Input type="text" placeholder="User ID" action>
        <input name="userId" data-sk-name="user-id" style={{ width: '250px' }} onChange={handleUserIdChange} />
        <Select
          onChange={handlePermissionChange}
          style={{ width: '320px' }}
          compact
          options={permissionSetOptions}
          defaultValue={permissionSet}
          data-sk-name="permission-set-select"
        />
        <Button data-sk-name="search-button" type="button" onClick={fetchPermissions} disabled={userId.length === 0}>
          Go
        </Button>
      </Input>
      <div className="tw-pt-4">
        <Checkbox checked={showAll} label="Show all" toggle onChange={handleShowAllChange} />
        <Segment color="blue" style={{ flexGrow: 0 }}>
          <Table celled basic="very" stackable size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Read</Table.HeaderCell>
                <Table.HeaderCell>Edit</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <TableLoaderWrap
              loading={loading}
              array={loading ? [] : Object.keys(basePermissions)}
              emptyMessage="No permissions loaded."
            >
              <Table.Body>
                {Object.keys(basePermissions).length > 0 &&
                  Object.keys(userPermissions).length > 0 &&
                  renderPermissions()}
              </Table.Body>
            </TableLoaderWrap>
          </Table>
        </Segment>
      </div>
    </div>
  )
}
