import { useQuery } from '@tanstack/react-query'
import {
  getDeliveries,
  getDeliveriesForApplication,
  getDelivery,
  getNextDeliveryForApplication
} from '../../actions/Deliveries'
import { createErrorToast } from '../../views/alertComponents/Alert'

export const useDeliveriesForApplicationAndVersionQuery = (applicationName: string, version: string) =>
  useQuery({
    queryKey: ['delivery', applicationName, version],
    queryFn: () => getDelivery({ applicationName, version }),
    useErrorBoundary: (error: unknown) => {
      // @ts-ignore
      if (error.response?.status === 404 || error.response?.status === 400) {
        createErrorToast(`No delivery found for application ${applicationName} with version ${version}.`)
      } else {
        createErrorToast(error)
      }
      return false
    },
    enabled: !!applicationName
  })

export const useDeliveriesNextForApplication = (applicationName?: string) =>
  useQuery({
    queryKey: ['nextDelivery', applicationName],
    queryFn: () => getNextDeliveryForApplication(applicationName!),
    enabled: !!applicationName,
    onError: error => createErrorToast(error)
  })

export const useDeliveriesForApplicationQuery = (applicationName?: string) =>
  useQuery({
    queryKey: ['delivery', applicationName],
    queryFn: () => getDeliveriesForApplication(applicationName!),
    enabled: !!applicationName
  })

export const useDeliveriesQuery = () =>
  useQuery({
    queryKey: ['deliveries'],
    queryFn: () => getDeliveries(),
    onError: error => createErrorToast(error)
  })
