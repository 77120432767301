import { Header } from 'semantic-ui-react'
import { Row } from 'react-table'
import dayjs from 'dayjs'
import { IConnectedFunction } from '../../../actions/ConnectedFunction'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { usePkgrService } from '../services/PkgrService'
import { formatDate } from '../../../utils/dateUtils'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { DatadogLambdaLogsButton } from '../../deployments/components/DatadogButtons'

interface ConnectedFunctionListProps {
  teamId: string
}

const ConnectedFunctionList = ({ teamId }: ConnectedFunctionListProps) => {
  const { data: teamData, isLoading: loadingTeamData } = useGetTeamQuery(teamId)
  const { connectedFunctions, isLoading: loadingConnectedFunction } = usePkgrService({
    tenantId: teamData?.tenantId || '',
    region: teamData?.region || ''
  })

  const now = dayjs().valueOf()
  const oneHourAgo = now - 60 * 60 * 1000

  const tableColumns = createMemoizedColumns<IConnectedFunction>(
    [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Package name',
        accessor: 'packageName'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Runtime',
        accessor: 'runtime'
      },
      {
        Header: 'Modified date',
        accessor: 'modifiedDate',
        Cell: ({ row }: { row: Row<IConnectedFunction> }) =>
          formatDate(row.original.modifiedDate, 'h:mma, ddd D MMM YYYY')
      },
      {
        Header: 'Created by',
        accessor: 'createdBy'
      },
      {
        Header: 'Spec version',
        accessor: 'specVersion'
      },
      {
        Header: 'Logs',
        accessor: 'lambdaName',
        Cell: ({ row }: { row: Row<IConnectedFunction> }) =>
          row.original.lambdaName ? (
            <DatadogLambdaLogsButton lambda={row.original.lambdaName} startTime={oneHourAgo} endTime={now} />
          ) : (
            <></>
          )
      }
    ],
    []
  )

  return (
    <div data-testid="connected-functions">
      <Header as="h3">Connected Functions</Header>
      <Table<IConnectedFunction>
        columns={tableColumns}
        data={connectedFunctions}
        loading={loadingTeamData || loadingConnectedFunction}
        emptyMessage="No connected functions found."
        color="blue"
        celled
      />
    </div>
  )
}

export default ConnectedFunctionList
