import { Row } from 'react-table'
import { Button, Icon, Popup } from 'semantic-ui-react'
import { IJiraTicket, IReleaseNoteInfo } from '../../../actions/ReleaseNotes'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { AddTicketToReleaseButton } from './AddTicketToFixVersionButton'

interface IProps {
  releaseNotes?: IReleaseNoteInfo
  tickets: IJiraTicket[]
  isLoading: boolean
  addTicketToFixVersionHook: (ticket: IJiraTicket) => Promise<void>
}

export const ReleaseNotesTicketsList = (props: IProps) => {
  const getTicketReleaseNotesRequirementValidationIconName = (ticket: IJiraTicket) => {
    switch (ticket.releaseNotesRequired) {
      case 'No':
        return 'check'
      case 'Yes':
        if (ticket.releaseNotesExist) {
          return 'check'
        } else {
          // any falsey
          return 'close'
        }
      case 'TBD':
      default:
        return 'question'
    }
  }

  const getTrProps: (row: Row<IJiraTicket>) => Record<string, any> = row => ({
    negative: !(row.original.addedToRelease && row.original.deployedInReleaseNoteDelivery),
    positive: row.original.addedToRelease && row.original.deployedInReleaseNoteDelivery
  })

  const getBooleanIcon = (value: boolean) =>
    value ? <Icon name="check" color="green" size="large" /> : <Icon name="x" color="red" size="large" />

  const columns = createMemoizedColumns<IJiraTicket>(
    [
      {
        Header: 'Type',
        Cell: ({ row }: { row: Row<IJiraTicket> }) => (
          <img src={row.original.issuetype.iconUrl} alt={row.original.issuetype.name} />
        )
      },
      {
        Header: 'Key',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'title'
      },
      {
        Header: 'Is in Jira Fix Version',
        Cell: ({ row }: { row: Row<IJiraTicket> }) => getBooleanIcon(row.original.addedToRelease)
      },
      {
        Header: 'Tagged in Delivery',
        Cell: ({ row }: { row: Row<IJiraTicket> }) => getBooleanIcon(row.original.deployedInReleaseNoteDelivery)
      },
      {
        id: 'releaseNotesStatus',
        Header: () => (
          <Popup
            trigger={
              <div>
                {' '}
                Notes <Icon name="question circle" />{' '}
              </div>
            }
            content={
              <p>
                <Icon name="check" /> Valid: ticket has a release notes requirement (&apos;Yes&apos; or &apos;No&apos;)
                that is met. <br />
                <Icon name="close" /> Invalid: ticket has requirement &apos;Yes&apos;, but no release notes. <br />
                <Icon name="question" /> Unassigned/Unknown: ticket has requirement &apos;TBD&apos;, or ticket
                requirement has an unexpected value.
              </p>
            }
            basic
            wide="very"
          />
        ),
        Cell: ({ row }: { row: Row<IJiraTicket> }) => (
          <Icon name={getTicketReleaseNotesRequirementValidationIconName(row.original)} />
        )
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Add to Jira Fix Version',
        Cell: ({ row }: { row: Row<IJiraTicket> }) => {
          if (!row.original.addedToRelease) {
            return (
              <AddTicketToReleaseButton
                release={props.releaseNotes}
                ticket={row.original}
                addTicketToFixVersionHook={props.addTicketToFixVersionHook}
              />
            )
          } else {
            return <div />
          }
        }
      },
      {
        Header: 'View',
        Cell: ({ row }: { row: Row<IJiraTicket> }) => (
          <Button
            icon="ticket"
            href={`https://skedulo.atlassian.net/browse/${row.original.id}`}
            className="square-icon-button"
            target="_blank"
          />
        )
      }
    ],
    [props.releaseNotes]
  )

  return (
    <Table<IJiraTicket>
      columns={columns}
      data={props.tickets}
      loading={props.isLoading}
      emptyMessage={'No Release Notes Found'}
      color="blue"
      getRowProps={getTrProps}
    />
  )
}
