import { IResetTeamInput } from '../views/newTeams/services/ResetTeamService'
import { INewTeamsRequest } from '../services/MigrateCustomerService'
import { DynamoQuery, DynamoQueryResponse, IResult, IResults } from './Utils'
import { authService, customerService, customerServiceConfig } from './Endpoints'
import { IAuthConnectionDetails } from './Teams'
export interface TeamLoginConnection {
  name: string
  displayName: string
}

export type DetailedAuthConnection = TeamLoginConnection & IAuthConnectionDetails

export type AuthConnections = TeamLoginConnection[]

export type DetailedAuthConnections = DetailedAuthConnection[]

export interface Environment {
  name: string
}

export type Environments = Environment[]

export interface Team {
  id: string
  primaryName: string
  description: string
  customerId: string
  tenantId: string
  isSalesforce: boolean
  region: string
  authProviderId?: string
  authProvider: string
  authConnections?: AuthConnections
  existingAuthConnections?: AuthConnections
  owner: string
  environment: string
  metadata: Record<string, any>
  updatedBy: string
  createdBy: string
  updatedAt: string
  createdAt: string
}

export interface InitialUser {
  email: string
  firstName: string
  lastName: string
  sendInvitation?: boolean
}

export type PatchTeam = Partial<Team>

export type NewTeam = Partial<Team> & { initialUser?: InitialUser }

export interface NewTeamPayload {
  environment?: string
  isSalesforce?: boolean
  team: NewTeam
}

export interface CreateTeamConfig {
  api: string
  team: NewTeamPayload
}

export type Vendor = 'salesforce' | 'salesforce-community'

export type VendorPrefix = 'sf-' | 'sfc-'

export interface LoginConnectionsConfig {
  vendor: Vendor
  teamId: string
  name: string
  instance: string
  displayName: string
}

export interface MigrateTeamConfig {
  api: string
  team: INewTeamsRequest
}

export interface ResetTeamConfig {
  api: string
  resetTeamInput: IResetTeamInput
}

export interface TeamsWithConnId {
  connectionId: string
  result: Team[]
}

export const getTeamEnvironments = () =>
  authService
    .get<IResult<Environments>>('/admin/team/environments')
    .then(response => response.data.result.map(env => env.name))

export const getTeams = (query: DynamoQuery) =>
  customerService.post<DynamoQueryResponse<Team>>('/admin/team/search', query).then(response => response.data)

export const getTeamsForCustomer = (customerId: string) => (query: DynamoQuery) =>
  customerService.get<DynamoQueryResponse<Team>>(`/admin/team/customerId/${customerId}`).then(response => response.data)

export const getTeamsByConnectionIds = (customerId: string, connectionIds: string) =>
  customerService
    .get<IResult<TeamsWithConnId[]>>('/admin/team/search/by-connection-ids', {
      params: { customerId, connectionIds }
    })
    .then(response => response.data.result)

export const getTeamById = (id: string) =>
  customerService.get<IResult<Team>>(`/admin/team/${id}`).then(response => response.data.result)

export const getTeamByName = (name: string) =>
  customerService.get<IResult<Team>>(`/admin/team/name/${name}`).then(response => response.data.result)

export const getTeamByTenant = (tenantId: string) =>
  customerService.get<IResult<Team>>(`/admin/team/tenantId/${tenantId}`).then(response => response.data.result)

export const createTeam = (createTeamConfig: CreateTeamConfig) =>
  customerService
    .post<IResult<Team>>('/admin/team', createTeamConfig.team, {
      ...customerServiceConfig,
      baseURL: `${createTeamConfig.api}/authorization`
    })
    .then(features => features.data.result)

export const updateTeam = (id: string) => (patchTeam: PatchTeam) =>
  customerService.patch<IResult<Team>>(`/admin/team/${id}`, patchTeam).then(response => response.data.result)

export const deleteTeam = (id: string) =>
  customerService.delete<string>(`/admin/team/${id}`).then(response => response.data)

export const getTeamNames = (id: string) =>
  customerService.get<IResults<string>>(`/admin/team/${id}/name`).then(response => response.data.results)

export const updateTeamName = (id: string) => (name: string) =>
  customerService.put<IResult<Team>>(`/admin/team/${id}/name`, { name }).then(response => response.data.result)

export const deleteTeamName = (id: string) => (name: string) =>
  customerService.delete(`/admin/team/${id}/name/${name}`).then(response => response.data)

export const createTeamLogin = (connection: LoginConnectionsConfig) =>
  customerService
    .post<IResult<TeamLoginConnection>>('/admin/team/connection', connection)
    .then(response => response.data.result)

export const deleteTeamLogin = (id: string) => (connectionName: string) =>
  customerService.delete(`/admin/team/${id}/connection/${connectionName}`).then(response => response.data)

export const migrateTeam = (migrateTeamConfig: MigrateTeamConfig) =>
  customerService
    .post<IResult<Team>>('/admin/team/migrate', migrateTeamConfig.team, {
      ...customerServiceConfig,
      baseURL: `${migrateTeamConfig.api}/authorization`
    })
    .then(response => response.data.result)

export const resetTeam = (id: string) => (resetTeamConfig: ResetTeamConfig) =>
  customerService
    .post<IResult<Team>>(`/admin/team/${id}/reset`, resetTeamConfig.resetTeamInput, {
      ...customerServiceConfig,
      baseURL: `${resetTeamConfig.api}/authorization`
    })
    .then(response => response.data.result)

export const createMultipleTeamConnections = (newConnections: LoginConnectionsConfig[]): Promise<TeamLoginConnection> =>
  recursiveCreateConnectionRequest(newConnections)

const recursiveCreateConnectionRequest = (connections: LoginConnectionsConfig[]): Promise<TeamLoginConnection> =>
  customerService
    .post<IResult<TeamLoginConnection>>('/admin/team/connection', connections[0])
    .then(response => {
      if (connections.length > 1) {
        return recursiveCreateConnectionRequest(connections.slice(1))
      }
      return response.data.result
    })
    .catch(error => error)
