import { useEffect, useMemo, useState } from 'react'
import { useBoolean, useDebounce } from 'usehooks-ts'
import { ExternalCustomer, InternalCustomer, NewCustomer, NewCustomerConfig } from '../../../actions/NewCustomers'
import { convertSalesforceId, validateSalesforceId } from '../../../utils/salesforce'
import { createErrorToast, createInfoToast, createSuccessToast } from '../../../views/alertComponents/Alert'
import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { useCreateTeamMutation } from '../../../mutations/CreateTeamMutation'
import {
  createMultipleTeamConnections,
  CreateTeamConfig,
  InitialUser,
  LoginConnectionsConfig,
  NewTeam,
  NewTeamPayload,
  Team,
  TeamLoginConnection
} from '../../../actions/NewTeams'
import { IRegionInfo } from '../../../actions/Regions'
import { INewLoginOption, LoginOption } from '../../../actions/Teams'
import { useGetEnvironmentsQuery } from '../../../queries/GetEnvironmentsQuery'
import { useValidateTeamService } from '../../../services/ValidateTeamService'

export interface CreateTeamService {
  newTeam: NewTeam
  initialUser: InitialUser
  regions: IRegionInfo[]
  loginOptions: INewLoginOption[]
  environmentOptions: string[]
  isLoading: boolean
  addCustomer: boolean
  isSalesforceTeam: boolean
  formErrors: FormErrors
  teamIsValid: boolean
  createNewTeam: (api: string, customerId?: string) => Promise<Team>
  createLoginConnections: (teamId: string, teamName: string) => Promise<string | void>
  runCreateTeamProcess: (
    newCustomer: NewCustomer,
    createCustomerFn: (newCustomerConfig: NewCustomerConfig) => Promise<InternalCustomer | ExternalCustomer>,
    processEndFn: (teamId: string) => void
  ) => Promise<void>
  handleTeamInput: (inputField: string) => (value: string) => void
  handleUserInput: (inputField: string) => (value: string) => void
  handleLoginInput: (index: number) => (inputField: string) => (value: string) => void
  addLoginOption: () => void
  removeLoginOption: (index: number) => () => void
  reset: () => void
  toggleAddCustomer: () => void
}

export interface FormErrors {
  [key: string]: FieldError
}

export interface FieldError {
  error: boolean
  message: string
}

export interface CreateTeamSuccessPayload {
  teamId: string
  connection?: TeamLoginConnection
}

export const defaultNewTeam: NewTeam = {
  primaryName: '',
  description: '',
  customerId: '',
  region: '',
  authProvider: 'auth0',
  metadata: {},
  owner: '',
  environment: '',
  tenantId: '',
  isSalesforce: undefined
}

export const defaultInitialUser: InitialUser = {
  email: '',
  firstName: '',
  lastName: ''
}

export const initialFormErrors: FormErrors = {
  primaryName: {
    error: false,
    message:
      'Team name must be lowercase, start with a letter, contain no spaces, and contain letters, numbers, or "-" character'
  },
  uniqueTeam: {
    error: false,
    message: 'This team name already exists. Team names must be unique.'
  },
  teamSalesforceId: {
    error: false,
    message: 'Must be a valid Salesforce account ID with 15 or 18 characters.'
  },
  userEmail: {
    error: false,
    message: 'Must be a valid email (e.g., valid-email@email.com)'
  },
  login: {
    error: false,
    message: `Endpoints must contain 2-63 characters: alphanumeric, '.' or '-'. Must start with a letter,
    contain no whitespaces and be a valid url.`
  }
}

export const validateTeamName = (name: string) => {
  if (name === '') {
    return true
  }

  return /^[a-z][a-z0-9-]{0,61}[a-z0-9]$/.test(name)
}

export const validateUserEmail = (email: string) => {
  if (email === '') {
    return true
  }

  // Very simple validation to simply check for string@string.string
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const validateEndpoint = (endpoint: string) => {
  if (endpoint === '') {
    return true
  }

  // Validates that it looks like an endpoint (e.g., test.endpoint works, not testendpoint)
  return endpoint.length <= 63 && /^[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&=\?_:;-]+$/.test(endpoint)
}

export const checkSalesforceId = (id: string) => {
  if (id === '') {
    return true
  }

  return validateSalesforceId(id)
}

export const useCreateTeamService = (suppliedCustomerRegion?: string): CreateTeamService => {
  const [newTeam, setNewTeam] = useState<NewTeam>(
    suppliedCustomerRegion ? { ...defaultNewTeam, region: suppliedCustomerRegion } : defaultNewTeam
  )
  const [initialUser, setInitialUser] = useState<InitialUser>(defaultInitialUser)
  const [loginOptions, setLoginOptions] = useState<INewLoginOption[]>([])
  const debouncedNewTeam = useDebounce(newTeam, 500)
  const debouncedInitialUser = useDebounce(initialUser, 500)
  const debouncedLoginOptions = useDebounce(loginOptions, 500)
  const [errors, setErrors] = useState<FormErrors>(initialFormErrors)
  const [isCreatingLogins, setIsCreatingLogins] = useState(false)

  const { mutateAsync: createTeamMutation, isLoading } = useCreateTeamMutation(() => reset())
  const { data: regionsInfo } = useGetRegionQuery()
  const { data: teamEnvironments } = useGetEnvironmentsQuery()
  // Validating team name uniqueness with BE
  const { teamNameIsValid: teamIsUnique, checkingTeamName, setEditedTeamName } = useValidateTeamService()

  const { value: addCustomer, toggle: toggleAddCustomer, setFalse: setAddCustomerFalse } = useBoolean(false)

  const { value: isSalesforceTeam, setFalse: setIsSalesforceFalse, setValue: setIsSalesforceTeam } = useBoolean()

  const reset = () => {
    setNewTeam(defaultNewTeam)
    setEditedTeamName('')
    setInitialUser(defaultInitialUser)
    setAddCustomerFalse()
    setIsSalesforceFalse()
    setLoginOptions([])
  }

  const validateTeam = () => {
    if (!validateTeamName(debouncedNewTeam.primaryName ?? '')) {
      setErrors({ ...errors, primaryName: { ...errors.primaryName, error: true } })
    } else if (errors.primaryName.error) {
      setErrors({ ...errors, primaryName: { ...errors.primaryName, error: false } })
    }

    if (debouncedNewTeam.primaryName && !checkingTeamName && !teamIsUnique) {
      setErrors({ ...errors, uniqueTeam: { ...errors.uniqueTeam, error: true } })
    } else if (errors.uniqueTeam.error) {
      setErrors({ ...errors, uniqueTeam: { ...errors.uniqueTeam, error: false } })
    }

    if (isSalesforceTeam && !checkSalesforceId(debouncedNewTeam.tenantId ?? '')) {
      setErrors({ ...errors, teamSalesforceId: { ...errors.teamSalesforceId, error: true } })
    } else if (errors.teamSalesforceId.error) {
      setErrors({ ...errors, teamSalesforceId: { ...errors.teamSalesforceId, error: false } })
    }
  }

  const validateUser = () => {
    if (!isSalesforceTeam && !validateUserEmail(debouncedInitialUser.email)) {
      setErrors({ ...errors, userEmail: { ...errors.userEmail, error: true } })
    } else if (errors.userEmail.error) {
      setErrors({ ...errors, userEmail: { ...errors.userEmail, error: false } })
    }
  }

  const validateLogin = () => {
    if (
      isSalesforceTeam &&
      debouncedLoginOptions.length > 0 &&
      debouncedLoginOptions.some(login => !validateEndpoint(login.endpoint))
    ) {
      setErrors({ ...errors, login: { ...errors.login, error: true } })
    } else if (errors.login.error) {
      setErrors({ ...errors, login: { ...errors.login, error: false } })
    }
  }

  useEffect(() => validateTeam(), [debouncedNewTeam, teamIsUnique, checkingTeamName])

  useEffect(() => validateUser(), [debouncedInitialUser])

  useEffect(() => validateLogin(), [debouncedLoginOptions])

  /**
   * Simple check if team form is complete and without errors.
   *
   * @returns true if form is ready for submission.
   */
  const validateForm = () => {
    // No Errors?
    const formHasErrors = Object.values(errors).some(e => e.error)

    // Check form is filled
    const initialUserInput = !isSalesforceTeam ? Object.values(initialUser).every(x => x !== '') : true

    const teamInput =
      newTeam.primaryName !== '' &&
      newTeam.description !== '' &&
      newTeam.environment !== '' &&
      newTeam.region !== '' &&
      newTeam.owner !== ''

    const customerInput = !addCustomer ? newTeam.customerId !== '' : true

    const tenantInput = isSalesforceTeam ? newTeam.tenantId !== '' : true

    const loginInput =
      isSalesforceTeam && loginOptions.length > 0
        ? loginOptions.every(login => Object.values(login).every(x => x !== ''))
        : true

    return (
      !formHasErrors && initialUserInput && teamInput && customerInput && tenantInput && loginInput && !checkingTeamName
    )
  }

  /**
   * Indicates that team data is ready for submission.
   */
  const teamIsValid = useMemo(
    () => validateForm(),
    [debouncedNewTeam, debouncedInitialUser, debouncedLoginOptions, errors, isSalesforceTeam]
  )

  /**
   * Create a new team with react-query mutateAsync promise.
   *
   * Should be called after a customer is created if we need to create one.
   *
   * If a customer was selected then will use customerId set in new team data.
   *
   * @param api - region specific api url
   * @param customerId - Created customer id if applicable.
   * @returns Create team mutation promise.
   */
  const createNewTeam = (api: string, customerId?: string): Promise<Team> => {
    const team = setupTeamPayload(customerId)
    const createTeamConfig: CreateTeamConfig = {
      api,
      team
    }
    createInfoToast(`Creating team: ${team.team.primaryName}`)
    return createTeamMutation(createTeamConfig)
  }

  /**
   * Sets up team payload for creation. Structure is dependent on whether the team is a salesforce team or standalone.
   *
   * @param customerId - Optionally provided customerId if a customer needed to be created.
   * @returns Payload for team creation.
   */
  const setupTeamPayload = (customerId?: string): NewTeamPayload => {
    const team: NewTeam = {
      primaryName: newTeam.primaryName,
      description: newTeam.description,
      customerId: customerId || newTeam.customerId,
      region: newTeam.region,
      authProvider: newTeam.authProvider,
      metadata: newTeam.metadata,
      owner: newTeam.owner,
      isSalesforce: newTeam.isSalesforce
    }
    if (newTeam.isSalesforce) {
      return {
        environment: newTeam.environment,
        team: {
          ...team,
          tenantId: newTeam.tenantId?.length === 15 ? convertSalesforceId(newTeam.tenantId) : newTeam.tenantId
        }
      }
    } else {
      return {
        environment: newTeam.environment,
        team: { ...team, initialUser }
      }
    }
  }

  const createLoginConnections = async (teamId: string, teamName: string): Promise<string | void> => {
    const connections: LoginConnectionsConfig[] = loginOptions.map((login, index) => ({
      vendor: 'salesforce',
      teamId,
      name: `${teamName}-conn-${index}`,
      instance: login.endpoint,
      displayName: login.loginButtonText
    }))
    setIsCreatingLogins(true)
    createInfoToast(`Creating login connections for the ${teamName} team.`)
    return createMultipleTeamConnections(connections)
      .then(_connection => {
        createSuccessToast(`Created login connections for team ${teamName}`)
        setIsCreatingLogins(false)
        return teamId
      })
      .catch(error => {
        setIsCreatingLogins(false)
        createErrorToast(error)
      })
  }

  /**
   * Create team process.
   *
   * 1 - Creates customer if required.
   * 2 - Creates team.
   * 3 - Creates login connection if required.
   *
   * @param newCustomer - Customer to be created.
   * @param createCustomerFn - Create customer promise.
   * @param processEndFn - Function to handle cleanup after process ends.
   * @returns Promise handling team creation.
   */
  const runCreateTeamProcess = (
    newCustomer: NewCustomer,
    createCustomerFn: (newCustomerConfig: NewCustomerConfig) => Promise<InternalCustomer | ExternalCustomer>,
    processEndFn: (teamId: string) => void
  ): Promise<void> => {
    // If able to run process, then we will find region info and api as form has dependency on regions for completion pre-submission
    const regionApi =
      regionsInfo?.find(regionInfo => regionInfo.region.toLowerCase() === newTeam.region?.toLowerCase())?.server.api ||
      ''

    if (addCustomer) {
      createInfoToast(`Creating customer: ${newCustomer.name}`)
      return createCustomerFn({ api: regionApi, customer: newCustomer } as NewCustomerConfig)
        .then(customer => createNewTeam(regionApi, customer.id))
        .then(team => {
          if (loginOptions.length > 0) {
            return createLoginConnections(team.id, team.primaryName)
          }
          return team.id
        })
        .then(teamId => processEndFn(teamId || ''))
        .catch(_error => {
          createErrorToast('Error occured during team creation.')
        })
    }
    return createNewTeam(regionApi)
      .then(team => {
        if (loginOptions.length > 0) {
          return createLoginConnections(team.id, team.primaryName)
        }
        return team.id
      })
      .then(teamId => processEndFn(teamId || ''))
      .catch(_error => {
        createErrorToast('Error occured during team creation.')
      })
  }

  const formatInput = (field: string, value: string) => {
    switch (field) {
      case 'primaryName':
      case 'endpoint':
        return value.trim().toLowerCase()
      default:
        return value
    }
  }

  const handleTeamInput = (inputField: string) => (value: string) => {
    if (inputField === 'primaryName') {
      setEditedTeamName(formatInput(inputField, value))
    }
    if (inputField === 'isSalesforce') {
      setIsSalesforceTeam(value === 'salesforce')
      setNewTeam({ ...newTeam, isSalesforce: value === 'salesforce' })
    } else {
      setNewTeam({ ...newTeam, [inputField]: formatInput(inputField, value) })
    }
  }

  const handleUserInput = (inputField: string) => (value: string) =>
    setInitialUser({ ...initialUser, [inputField]: value })

  const handleLoginInput = (index: number) => (inputField: string) => (value: string) => {
    const logins = [...loginOptions]
    if (inputField === 'loginOption') {
      logins[index][inputField as 'loginOption'] = formatInput(inputField, value) as LoginOption
    } else {
      logins[index][inputField as 'loginButtonText' | 'endpoint'] = formatInput(inputField, value)
    }
    setLoginOptions([...logins])
  }

  const addLoginOption = () => {
    const newLogin: INewLoginOption = { loginOption: 'salesforce-custom-endpoint', loginButtonText: '', endpoint: '' }
    setLoginOptions([...loginOptions, newLogin])
  }

  const removeLoginOption = (index: number) => () => {
    const logins = [...loginOptions]
    logins.splice(index, 1)
    setLoginOptions([...logins])
  }

  return {
    newTeam,
    initialUser,
    regions: regionsInfo ?? [],
    loginOptions,
    environmentOptions: teamEnvironments ?? [],
    isLoading: isLoading || isCreatingLogins,
    addCustomer,
    isSalesforceTeam,
    formErrors: errors,
    teamIsValid,
    createNewTeam,
    createLoginConnections,
    runCreateTeamProcess,
    handleTeamInput,
    handleUserInput,
    handleLoginInput,
    addLoginOption,
    removeLoginOption,
    reset,
    toggleAddCustomer
  }
}
