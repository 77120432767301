import * as React from 'react'
import { DropdownItemProps, DropdownOnSearchChangeData, DropdownProps, Form, FormFieldProps } from 'semantic-ui-react'
import { IComponent } from 'src/actions/Components'
import { useFilterService } from '../../services/DynamoQueryFilterService'
import { getCustomers, ICustomer } from '../../actions/Customers'
import { IReleaseApplication } from '../../actions/Releases'
import { createErrorToast } from '../alertComponents/Alert'
import { useCustomersService } from '../newCustomers/services/CustomersService'
import { useGetCustomerQuery } from '../../queries/GetCustomerQuery'
import { Customer } from '../../actions/NewCustomers'

interface ISelectorProps {
  name?: string
  value: string
  onChange?(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void
  default?: boolean
  label?: string
  id?: string
  options?: DropdownItemProps[]
  width?: FormFieldProps['width']
  disabled?: boolean
  required?: boolean
  placeholder?: string
  onSearchChange?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => void
  editFn?: (editedValue: string) => void
}

interface ISelectorState {
  options: {
    text: string
    value: string
  }[]
  loading: boolean
}

const parseCustomers = (customers: ICustomer[] | Customer[]) =>
  customers.map(customer => ({
    text: customer.name,
    value: customer.id
  }))

export const CustomerSelectorNew = (props: ISelectorProps) => {
  const { dynamoQueryFilter, handleSearchChange } = useFilterService(['name'], 'name', {})
  const { data: customers } = useCustomersService(dynamoQueryFilter || {}, 50)
  const { data: customer } = useGetCustomerQuery(props.value)
  const customersList = customers?.pages.flatMap(page => page.results) ?? []
  customer && !customersList.find(c => c.id === customer.id) && customersList.push(customer)
  const customerOptions: DropdownItemProps[] = React.useMemo(() => parseCustomers(customersList), [customers, customer])

  return (
    <Form.Select
      search
      fluid
      value={props.value}
      options={customerOptions}
      onChange={(_e, data) => {
        props.editFn?.(data.value as string)
        handleSearchChange({}, { value: data.searchQuery ?? '' })
      }}
      onSearchChange={(_e, data) => {
        handleSearchChange({}, { value: data.searchQuery ?? '' })
      }}
      data-testid="customer-selection"
    />
  )
}

export class CustomerSelector extends React.PureComponent<ISelectorProps, ISelectorState> {
  constructor(props: ISelectorProps) {
    super(props)
    this.state = {
      loading: true,
      options: [{ text: '', value: '' }]
    }
  }

  componentDidMount() {
    getCustomers().then(
      customers => this.setState({ loading: false, options: parseCustomers(customers) }),
      err => {
        this.setState({ loading: false })
        createErrorToast(err)
      }
    )
  }

  render() {
    const { name, label, onChange, value, id, width } = this.props
    const { options, loading } = this.state

    return (
      <Form.Select
        label={label}
        search={true}
        placeholder="Customer"
        name={name || 'customerID'}
        options={options || [{ text: '', value: '' }]}
        onChange={onChange}
        value={value}
        fluid
        id={id}
        loading={loading}
        width={width}
      />
    )
  }
}

export const LoginOptionSelector = (props: ISelectorProps) => (
  <Form.Select
    required={true}
    options={
      props.default
        ? [
            { text: 'Salesforce and Sandbox', value: 'salesforce' },
            { text: 'Salesforce Custom Endpoint', value: 'salesforce-custom-endpoint' },
            { text: 'Skedulo', value: 'skedulo' }
          ]
        : [{ text: 'Salesforce Custom Endpoint', value: 'salesforce-custom-endpoint' }]
    }
    {...props}
  />
)

export const SearchableSelector = (props: ISelectorProps) => (
  <Form.Select
    search
    fluid
    placeholder={props.label || props.placeholder}
    name={(props.label || '').toLowerCase()}
    label={props.label}
    {...props}
    options={props.options || [{ key: '', text: '', value: '' }]}
    required={props.required}
  />
)

interface IApplicationSelectorProps {
  applications: IReleaseApplication[]
  value: string
  onChange: (event: React.SyntheticEvent, data: DropdownProps) => void
  acceptNull?: boolean
  label?: string
  width?: FormFieldProps['width']
}

export const ApplicationSelector = (props: IApplicationSelectorProps) => {
  const options = props.applications.map(application => ({
    text: application.applicationName,
    value: application.applicationName
  }))
  if (props.acceptNull) {
    options.unshift({ text: 'No Application', value: '' })
  }
  return (
    <SearchableSelector
      name="applicationName"
      options={options.sort()}
      value={props.value}
      onChange={props.onChange}
      label={props.label}
      width={props.width}
    />
  )
}

interface IComponentSelectorProps {
  components: IComponent[]
  value: string
  onChange: (event: React.SyntheticEvent, data: DropdownProps) => void
  acceptNull?: boolean
  label?: string
  width?: FormFieldProps['width']
}

export const ComponentSelector = (props: IComponentSelectorProps) => {
  const options = props.components
    .map(component => ({
      text: component.componentName,
      value: component.componentName
    }))
    .sort((a, b) => a.text.localeCompare(b.text))
  if (props.acceptNull) {
    options.unshift({ text: 'No Component', value: '' })
  }
  return (
    <SearchableSelector
      name="componentName"
      options={options}
      value={props.value}
      onChange={props.onChange}
      label={props.label}
      width={props.width}
    />
  )
}
