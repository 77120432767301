import * as _ from 'lodash'
import { Breadcrumb, Button, Divider, Icon, Loader, Menu, Table } from 'semantic-ui-react'
import { useRouterProps } from '../../router/RouterProps'
import { useDeploymentQuery } from '../../queries/deployments/GetDeploymentsQuery'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { SimpleModal } from '../modalComponents/SimpleModal'
import { useDeploymentCancelMutation } from '../../mutations/deployments/CancelDeploymentMutation'
import { DeploymentStatus } from './components/DeploymentStatus'

export const DeploymentDetails = () => {
  const routerProps = useRouterProps()
  const { documentTitle, setDocumentTitle } = useDocumentTitle('Deployment Details')
  const { mutate: cancelDeployment } = useDeploymentCancelMutation()

  const id = routerProps.params.id || ''
  const env = routerProps.params.env || ''
  const instance = routerProps.params.instance || ''
  const { data: deployment, status: deploymentStatus } = useDeploymentQuery(env, instance, id)

  if (deployment?.application && documentTitle !== deployment?.application) {
    setDocumentTitle(deployment?.application)
  }

  const getAWXUrl = () => {
    if (!deployment) {
      return '#'
    } else {
      const awxEnv = deployment.env === 'staging' ? 'test' : deployment.env
      return `https://awx.${deployment.instance}.private.${awxEnv}.skl.io/#/jobs/playbook/${deployment.id}`
    }
  }

  return (
    <div className="route-component">
      <Menu secondary fluid>
        <Menu.Menu position="left">
          <Menu.Item>
            <Breadcrumb>
              <Breadcrumb.Section className="back-button" onClick={() => routerProps.navigate(-1)}>
                <Icon name="chevron left" size="big" />
                Back
              </Breadcrumb.Section>
            </Breadcrumb>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {deployment?.application && (
        <Table basic="very" collapsing textAlign="center" size="large" className="deployment-details">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{_.capitalize(deployment.application)}</Table.HeaderCell>
              <Table.HeaderCell>Env: {_.capitalize(deployment.env)}</Table.HeaderCell>
              <Table.HeaderCell>Instance: {deployment.instance}</Table.HeaderCell>
              <Table.HeaderCell>Tag: {deployment.tag}</Table.HeaderCell>
              <Table.HeaderCell>Config: {deployment.config}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <DeploymentStatus
                  status={deployment.status}
                  checkMode={deployment.check_mode}
                  explanation={deployment.explanation}
                />
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="center">
                {(deployment.status === 'running' ||
                  deployment.status === 'waiting' ||
                  deployment.status === 'pending') && (
                  <SimpleModal
                    submitFn={async () =>
                      cancelDeployment({
                        environment: deployment?.env,
                        instance: deployment?.instance,
                        id: deployment.id
                      })
                    }
                    header={'Cancel Deployment'}
                    content={'Are you sure you want to cancel this deployment'}
                    successMessage={`Successfully deleted deployment ${deployment.id}`}
                    buttonClassName="form-button-sked-blue"
                  />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
      )}
      {deployment?.explanation && <div>{deployment.explanation}</div>}
      <Button
        href={getAWXUrl()}
        target="_blank"
        rel="noreferrer"
        disabled={deploymentStatus === 'loading'}
        icon
        labelPosition="left"
        name="link to awx job"
      >
        <Icon name="server" />
        View in AWX
      </Button>
      <Divider />
      <div className="console">
        <div dangerouslySetInnerHTML={{ __html: deployment?.output || '' }} />
        {(!deployment || deployment.status === 'running' || deployment.status === 'pending') && (
          <Loader size="tiny" active inline />
        )}
        <Loader active={deploymentStatus === 'loading'} size="large" />
      </div>
    </div>
  )
}
