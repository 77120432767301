import _ from 'lodash'
import { Loader, Segment } from 'semantic-ui-react'
import { Delivery, IDeliveryCommitsCompareResult } from '../../../actions/Deliveries'
import { IDeliveryCompareDetails } from './DeliveryCompare'

interface IProps {
  base?: Delivery
  head: IDeliveryCompareDetails // head, with pre-computed details
  commits?: IDeliveryCommitsCompareResult // commit diff from HEAD to BASE
  loadingCommits: boolean
}

export const DeliveryCompareMessage = (props: IProps) => {
  const commitsCompareMessage = () => {
    if (props.loadingCommits) {
      return (
        <span>
          <Loader active inline size={'mini'} /> commits <Loader active inline size={'mini'} />
        </span>
      )
    }
    if (!_.isUndefined(props.commits)) {
      const commitsMessage: string[] = []
      if (props.commits.ahead_by > 0) {
        commitsMessage.push(`${props.commits.ahead_by} commits ahead of`)
      }
      if (props.commits.behind_by > 0) {
        commitsMessage.push(`${props.commits.behind_by} commits behind`)
      }
      if (props.commits.total_commits === 0) {
        commitsMessage.push('equal in commits to')
      }
      return commitsMessage.join(' and ')
    } else {
      return ''
    }
  }

  const { delivery, dayDiff, idxDiff } = props.head
  if (delivery?.id === props.base?.id) {
    return (
      <Segment>
        <b style={{ fontWeight: 'bold' }}>{delivery?.version}</b> is{' '}
        <b style={{ fontWeight: 'bold' }}>{props.base?.version}</b>
      </Segment>
    )
  } else {
    return (
      <Segment>
        <b style={{ fontWeight: 'bold' }}>{delivery?.version}</b> is {Math.abs(dayDiff)} days{' '}
        {dayDiff > 0 ? 'older' : 'newer'}, {Math.abs(idxDiff)} deliveries {idxDiff > 0 ? 'ahead' : 'behind'}, and{' '}
        {commitsCompareMessage()} delivery <b style={{ fontWeight: 'bold' }}>{props.base?.version}</b>
      </Segment>
    )
  }
}
