import { Grid, Icon } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { useGetReleaseNotesQuery } from '../../queries/releaseNotes/GetReleaseNotesQuery'
import { IReleaseNote } from '../../actions/ReleaseNotes'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

import { SimpleSearch, useSimpleSearch } from '../searchComponents/SimpleSearch'
import { CreateReleaseNotesModal } from './Components/CreateReleaseNotesModal'
import { ReleaseNotesList } from './Components/ReleaseNotesList'

const title = 'Release Notes'

export const ReleaseNotes = () => {
  const { data: releaseNotes, status, refetch } = useGetReleaseNotesQuery()
  useDocumentTitle(title)
  const navigate = useNavigate()

  const searchOptions = [
    { text: 'All', value: 'all' },
    { text: 'Version', value: 'releaseVersion' },
    { text: 'Component', value: 'componentName' },
    { text: 'Released', value: 'released' }
  ]
  const simpleSearch = useSimpleSearch(searchOptions)

  const createDetailsLink = (releaseNote: IReleaseNote) => () => {
    navigate(`/applications/release-notes/${releaseNote.componentName}/${releaseNote.releaseVersion}`)
  }

  return (
    <div className="route-component">
      {/* Controls Grid */}
      <Grid columns={3} verticalAlign="middle" stackable>
        <Grid.Column>
          <Grid verticalAlign="middle">
            <Grid.Column>
              <Icon
                size="large"
                loading={status === 'loading'}
                name="refresh"
                className="clickable"
                onClick={refetch}
                aria-label="Refresh Release Notes"
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleSearch simpleSearch={simpleSearch} />
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column textAlign="right" floated="right">
          <CreateReleaseNotesModal releaseNotes={releaseNotes ?? []} loading={status === 'loading'} />
        </Grid.Column>
      </Grid>
      <ReleaseNotesList
        releaseNotes={releaseNotes ?? []}
        searchValue={simpleSearch}
        isLoading={status === 'loading'}
        openReleaseNotesDetailHook={createDetailsLink}
      />
    </div>
  )
}
