import { useQuery } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { getDeliveriesForReleaseNotes } from '../../actions/ReleaseNotes'

export const useDeliveriesForReleaseNotesQuery = (componentName: string, version: string) =>
  useQuery({
    queryKey: ['deliveryForReleaseNote', componentName, version],
    queryFn: () => getDeliveriesForReleaseNotes(componentName, version),
    useErrorBoundary: (error: unknown) => {
      // @ts-ignore
      if (error.response?.status === 404 || error.response?.status === 400) {
        createErrorToast(`No delivery found for release note with component  ${componentName} with version ${version}.`)
      } else {
        createErrorToast(error)
      }
      return false
    },
    enabled: !!componentName
  })
