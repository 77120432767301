import { Row } from 'react-table'
import { Button, Icon, Popup } from 'semantic-ui-react'
import { IReleaseNote } from '../../../actions/ReleaseNotes'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { ISimpleSearchValue } from '../../searchComponents/SimpleSearch'
import { filterData } from '../../tableComponents/TableFunctions'

interface IProps {
  releaseNotes: IReleaseNote[]
  searchValue: ISimpleSearchValue
  isLoading: boolean
  openReleaseNotesDetailHook: (releaseNote: IReleaseNote) => () => void
}

const getReleasedIcon = (value: boolean) =>
  value ? (
    <Popup position="right center" trigger={<Icon name="check" color="green" size="large" />} content="Released" />
  ) : (
    <Popup position="right center" trigger={<Icon name="clock" color="black" size="large" />} content="Pending" />
  )

export const ReleaseNotesList = (props: IProps) => {
  const searchValue = props.searchValue
  const columns = createMemoizedColumns<IReleaseNote>([
    {
      Header: 'Component',
      accessor: 'componentName'
    },
    {
      Header: 'Version',
      accessor: 'releaseVersion'
    },
    {
      Header: 'Released / Pending',
      Cell: ({ row }: { row: Row<IReleaseNote> }) => getReleasedIcon(row.original.released)
    },
    {
      Header: 'Details',
      Cell: ({ row }: { row: Row<IReleaseNote> }) => (
        <Button onClick={props.openReleaseNotesDetailHook(row.original)} icon={'file alternate outline'} />
      )
    }
  ])

  const filteredReleaseNotes: IReleaseNote[] = filterData(
    { searchValue: searchValue.searchText, searchCategory: searchValue.searchCategory },
    props.releaseNotes
  )

  return (
    <Table<IReleaseNote>
      columns={columns}
      data={filteredReleaseNotes}
      loading={props.isLoading}
      emptyMessage={'No Release Notes Found'}
      color="blue"
    />
  )
}
