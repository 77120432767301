import * as React from 'react'
import { Header, Icon, List, Segment } from 'semantic-ui-react'
import dayjs from 'dayjs'
import { IApplication } from '../../../actions/Applications'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'

import { DatadogApplicationLogsButton } from '../../deployments/components/DatadogButtons'
import { Spinner } from '../../../App'
import { GithubURL } from '../../deliveries/Components/GithubURL'
import { IReleaseApplication } from '../../../actions/Releases'

export const ApplicationInformation = (props: {
  application?: IApplication
  releaseApplication?: IReleaseApplication
  name: string
  environment: string
  instance: string
}) => {
  const { application, releaseApplication } = props
  const now = dayjs().valueOf()
  const oneHourAgo = now - 60 * 60 * 1000

  return (
    <div>
      <Header as="h3">Application Info</Header>
      <Segment color="blue">
        <LoaderWrap loading={application === undefined}>
          <React.Fragment>
            <Header as="h4" name="name-header">
              {application?.meta.name || props.name}
            </Header>
            <List name="info-list">
              <List.Item>
                <List.Header>Name</List.Header>
                {application?.name || application?.meta.name || props.name}
              </List.Item>
              <List.Item>
                <List.Header>Environment</List.Header>
                {props.environment}
              </List.Item>
              <List.Item>
                <List.Header>Instance</List.Header>
                {props.instance}
              </List.Item>
              <List.Item>
                <List.Header>Tag</List.Header>
                <div className="table-cell-overflow">
                  {releaseApplication && application?.meta.tag ? (
                    <>
                      <GithubURL
                        owner={releaseApplication.repositoryOwner}
                        repository={releaseApplication.repositoryName}
                        commit={application.meta.tag}
                      />{' '}
                      <Icon name="external" />
                    </>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </List.Item>
              <List.Item>
                <List.Header>Containers:</List.Header>
                <List>
                  <List.Item>Ready: {application?.meta.ready || 0}</List.Item>
                  <List.Item>Available: {application?.meta.available || 0}</List.Item>
                  <List.Item>Unavailable: {application?.meta.unavailable || 0}</List.Item>
                  <List.Item>Desired: {application?.meta.desired || 0}</List.Item>
                  <List.Item>Total: {application?.meta.total || 0}</List.Item>
                  <List.Item>Updated: {application?.meta.updated || 0}</List.Item>
                </List>
              </List.Item>
            </List>
            <DatadogApplicationLogsButton
              application={props.application?.name ?? ''} // hidden by loader when undefined
              env={props.environment}
              instance={props.instance}
              startTime={oneHourAgo}
              endTime={now}
              title="Datadog Logs"
            />
          </React.Fragment>
        </LoaderWrap>
      </Segment>
    </div>
  )
}
