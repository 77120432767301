import { Button, Table } from 'semantic-ui-react'
import { IReleaseApplication } from 'src/actions/Releases'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { listJiraURLFromCommitMessage } from './getJiraURLFromCommitMessage'

interface IProps {
  commits: any[]
  application: IReleaseApplication
  loading: boolean
  emptyMessage?: string
}

export const DeliveryCommitsList = (props: IProps) => (
  <Table striped color="blue" stackable aria-label="delivery-commits">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Message</Table.HeaderCell>
        <Table.HeaderCell>Jira Issue</Table.HeaderCell>
        <Table.HeaderCell>Author</Table.HeaderCell>
        <Table.HeaderCell>Time</Table.HeaderCell>
        <Table.HeaderCell>Link</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <TableLoaderWrap
      loading={props.loading}
      array={props.commits}
      emptyMessage={props.emptyMessage || 'No commits found for this Delivery'}
    >
      <Table.Body>
        {props.commits.map(commit => (
          <Table.Row key={commit.sha}>
            <Table.Cell>{commit.message}</Table.Cell>
            <Table.Cell>{listJiraURLFromCommitMessage(commit.message)}</Table.Cell>
            <Table.Cell>{commit.author.name}</Table.Cell>
            <Table.Cell>{commit.timestamp}</Table.Cell>
            <Table.Cell>
              <Button
                icon="github"
                href={`https://github.com/Skedulo/${props.application.repositoryName}/commit/${commit.sha}`}
                className="square-icon-button"
                target="_blank"
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </TableLoaderWrap>
  </Table>
)
