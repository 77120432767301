import * as React from 'react'
import { Button, Form, InputProps, Modal } from 'semantic-ui-react'
import { createReleaseApplication } from '../../../actions/Admin'
import { IReleaseApplication } from '../../../actions/Releases'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'

export interface IProps {
  updateApplications: () => void
}

export interface IState {
  open: boolean
  application: IReleaseApplication
  submitting: boolean
}

const emptyApplication = {
  applicationName: '',
  component: '',
  repositoryName: '',
  repositoryOwner: '',
  normalRelease: true
}

export class CreateReleaseApplicationModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      open: false,
      application: emptyApplication,
      submitting: false
    }
  }

  submit = async () => {
    try {
      this.setState({ submitting: true })
      const application = await createReleaseApplication(this.state.application)
      this.setState({ submitting: false })
      createSuccessToast(`Application ${application.applicationName} created.`)
      this.props.updateApplications()
      this.toggleOpen()
      this.clearForm()
    } catch (error) {
      this.setState({ submitting: false })
      createErrorToast(error)
    }
  }

  clearForm = () => this.setState({ application: emptyApplication })

  toggleOpen = () => this.setState(prevState => ({ open: !prevState.open }))

  onChange = (event: React.SyntheticEvent, data: InputProps) => {
    const application = { ...this.state.application, [data.name]: data.value }
    this.setState({ application })
  }

  toggleNormalRelease = () => {
    const application = { ...this.state.application, normalRelease: !this.state.application.normalRelease }
    this.setState({ application })
  }

  render() {
    const { application, open } = this.state
    return (
      <Modal
        open={open}
        onClose={this.toggleOpen}
        closeIcon
        size="tiny"
        trigger={<Button color="blue" content="Create Release Application" onClick={this.toggleOpen} />}
      >
        <Modal.Header>Create Release Application</Modal.Header>
        <Modal.Content scrolling>
          <Form onSubmit={this.submit}>
            <Form.Input
              placeholder="Application Name"
              label="Application Name"
              required
              name="applicationName"
              onChange={this.onChange}
              value={application.applicationName}
            />
            <Form.Input
              placeholder="Component"
              label="Component"
              required
              name="component"
              onChange={this.onChange}
              value={application.component}
            />
            <Form.Input
              placeholder="Repository Name"
              label="Repository Name"
              required
              name="repositoryName"
              onChange={this.onChange}
              value={application.repositoryName}
            />
            <Form.Input
              placeholder="Repository Owner"
              label="Repository Owner"
              required
              name="repositoryOwner"
              onChange={this.onChange}
              value={application.repositoryOwner}
            />
            <Form.Checkbox
              label="Normal Release"
              checked={application.normalRelease}
              onClick={this.toggleNormalRelease}
            />
            <Form.Button
              id="modal-button-add-team"
              type="submit"
              fluid
              className="form-button-sked-blue"
              content="Submit"
              loading={this.state.submitting}
            />
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
