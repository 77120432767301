import dayjs from 'dayjs'
import React, { useCallback, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import { DropdownProps, Form, Loader, Grid } from 'semantic-ui-react'
import isoWeek from 'dayjs/plugin/isoWeek'
import { useGetComponentsQuery } from '../../../queries/applications/component/GetComponentsQuery'
import { ComponentSelector } from '../../formComponents/Selectors'
import { INewReleaseNote, IReleaseNote } from '../../../actions/ReleaseNotes'
import 'react-datepicker/dist/react-datepicker.css'

dayjs.extend(isoWeek)

const getVersionFromDate = (releaseNoteVersions: string[], date: Date) => {
  const dayjsDate = dayjs(date)
  // find number of releases in selected week
  /* Because we store version as a string, I am not sure how to do this except with brute force date or string comparison.
  We assume for this comparison that the release notes are sorted by release date with the latest release note first.
  I am assuming that this method is faster than Date comparison as that requires calculating the ISO Week of each timestamp,
  whereas this uses a string comparison. */
  const upperBound = `v${dayjsDate.isoWeekYear() % 100}.${dayjsDate.isoWeek() + 1}.0`
  const lowerBound = `v${dayjsDate.isoWeekYear() % 100}.${dayjsDate.isoWeek()}.0`
  let thisWeeksReleasesCount = 0
  for (const rnv of releaseNoteVersions) {
    // compare versions
    const compare = rnv
    if (compare >= upperBound) {
      continue // date ver later than week after selected
    } else if (compare < lowerBound) {
      break // date ver earlier than selected week
    } else {
      thisWeeksReleasesCount++ // this date ver was in the selected week
    }
  }
  // dayjs format does not have 2-day ISO Week Year, sequence number is number of existing releases in the week
  return `v${dayjsDate.isoWeekYear() % 100}.${dayjsDate.isoWeek()}.${thisWeeksReleasesCount}`
}

const findBaseVersion = (releaseNoteVersions: string[], version: string) =>
  releaseNoteVersions.find(releaseNoteVersion => releaseNoteVersion < version)

interface IProps {
  releaseNotes: IReleaseNote[]
  loading: boolean
  onChange: (releaseNote: INewReleaseNote | undefined) => void
  initialValue?: Partial<INewReleaseNote>
}

export const CreateReleaseNotesForm = (props: IProps) => {
  const getInitialComponentName = useCallback(() => props.initialValue?.componentName ?? '', [props.initialValue])

  const getInitialReleaseDate = useCallback(() => props.initialValue?.releaseDate ?? new Date(0), [props.initialValue])

  const getInitialCreateJira = useCallback(() => !(props.initialValue?.noJira ?? false), [props.initialValue])

  const [componentName, setComponentName] = React.useState(getInitialComponentName())

  const releaseNoteVersions = useMemo(
    () => props.releaseNotes.filter(rn => rn.componentName === componentName).map(rn => rn.releaseVersion),
    [componentName, props.releaseNotes]
  )

  const getInitialReleaseVersion = () => {
    if (props.initialValue?.componentName && props.initialValue?.releaseDate) {
      return getVersionFromDate(releaseNoteVersions, props.initialValue.releaseDate)
    } else {
      return ''
    }
  }

  const getInitialBaseVersion = () => {
    if (props.initialValue?.componentName && props.initialValue?.releaseDate) {
      const initVer = getVersionFromDate(releaseNoteVersions, props.initialValue.releaseDate)
      return findBaseVersion(releaseNoteVersions, initVer) || ''
    } else {
      return ''
    }
  }

  const { data: components, status: componentsStatus } = useGetComponentsQuery()
  const [releaseVersion, setReleaseVersion] = React.useState(getInitialReleaseVersion())
  const [releaseDate, setReleaseDate] = React.useState<Date>(getInitialReleaseDate())
  const [baseVersion, setBaseVersion] = React.useState<string>(getInitialBaseVersion())
  const [createJira, setCreateJira] = React.useState(getInitialCreateJira())

  /* Methods for finding/updating values */
  const changeComponent = (_event: React.SyntheticEvent, data: DropdownProps) => {
    setComponentName(String(data.value))
  }

  const yesterday = useMemo(() => dayjs().startOf('day').subtract(1, 'second').toDate(), [])

  const onChange = props.onChange

  /* Effects to Update Parent Element */
  React.useEffect(() => {
    if (componentName && releaseDate && releaseDate > yesterday) {
      // set the release version
      const newReleaseVersion = getVersionFromDate(releaseNoteVersions, releaseDate)
      setReleaseVersion(newReleaseVersion)
      // set the base version
      const newBaseVersion = findBaseVersion(releaseNoteVersions, newReleaseVersion) || ''
      setBaseVersion(newBaseVersion)
      onChange({
        componentName,
        releaseDate,
        releaseVersion: newReleaseVersion,
        baseVersion: newBaseVersion ?? '',
        noJira: !createJira
      })
    } else {
      setReleaseVersion('')
      setBaseVersion('')
      onChange(undefined) // undefined if release-note not complete
    }
  }, [releaseDate, componentName, createJira, releaseNoteVersions, onChange, yesterday])

  React.useEffect(() => {
    // console.log(`${componentName} ${releaseDate} ${createJira}`)
    if (componentName && releaseDate) {
      onChange({
        componentName,
        releaseDate,
        releaseVersion,
        baseVersion: baseVersion ?? '',
        noJira: !createJira
      })
    }
  }, [createJira, baseVersion, componentName, releaseDate, onChange, releaseVersion])

  /* Initial Value Controls */
  const resetToInitialValue = useCallback(() => {
    setComponentName(getInitialComponentName())
    setReleaseDate(getInitialReleaseDate())
    // releaseVersion and baseVersion will be changed to initial values as a side effect
    setCreateJira(getInitialCreateJira())
  }, [getInitialComponentName, getInitialReleaseDate, getInitialCreateJira])

  return (
    <Form>
      {/* Component Name */}
      <Form.Field id={'componentName'}>
        <Loader active={componentsStatus === 'loading'} />
        <ComponentSelector
          components={components ?? []}
          value={componentName}
          onChange={changeComponent}
          label="Component"
          width={8}
        />
      </Form.Field>
      {/* Release Date */}
      <Form.Field>
        <label htmlFor="releaseDate">Release Date</label>
        <DatePicker
          id="releaseDate"
          selected={releaseDate > yesterday ? releaseDate : null}
          onChange={date => setReleaseDate(date!)}
          minDate={yesterday}
        />
      </Form.Field>
      <Form.Input
        id="releaseVersion"
        label={{ htmlFor: 'releaseVersion', children: 'Release Version' }}
        value={releaseVersion}
      />

      <Form.Input id="baseVersion" label={{ htmlFor: 'baseVersion', children: 'Base Version' }} value={baseVersion} />

      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column floated="left" width={4}>
            <Form.Checkbox
              label={<label htmlFor="createJira">Create Jira</label>}
              id="createJira"
              toggle
              checked={createJira}
              onChange={(_evt, data) => {
                setCreateJira(!!data.checked)
              }}
            />
          </Grid.Column>
          <Grid.Column floated="right" width={4} verticalAlign="bottom">
            <Form.Button type="reset" content="Reset" onClick={resetToInitialValue} fluid />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}
