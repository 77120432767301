import { chunk, flatten, groupBy, keyBy } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { expensiveQueryConfig } from '../views/Utils'
import { getUserSummary, IUserSummary } from '../actions/Tenants'
import { TeamWithApi, TeamWithSummary } from '../views/newCustomers/component/CustomerUsers'

export const getSummaryForTeams = async (teams: TeamWithApi[]): Promise<TeamWithSummary[]> => {
  const teamsByRegion = groupBy<TeamWithApi>(teams, 'api')
  const summaryRequests = Object.entries(teamsByRegion).flatMap(async ([api, regionTeams]) => {
    const teamsChunked = chunk(regionTeams, 100).map(async regionTeamsChunk =>
      getUserSummary(
        regionTeamsChunk.map(team => team.team.tenantId),
        api
      )
    )
    const teamsWithSummary = await Promise.all(teamsChunked)
    return flatten(teamsWithSummary)
  })
  const sumariesChunked = await Promise.all(summaryRequests)
  const summaries = flatten(sumariesChunked)
  const summaryByTenantId = keyBy<IUserSummary>(summaries, 'tenantId')
  return teams.map(teamWithApi => ({
    team: teamWithApi.team,
    summary: summaryByTenantId[teamWithApi.team.tenantId] || {
      tenantId: teamWithApi.team.tenantId,
      totalActiveResources: null,
      totalActiveSchedulers: null
    }
  }))
}

export const useGetUserSummaryOverviewForTeamsQuery = (teamsData: TeamWithApi[]) =>
  useQuery<TeamWithSummary[]>(['teams', 'summary', teamsData], () => getSummaryForTeams(teamsData), {
    enabled: !!teamsData,
    ...expensiveQueryConfig
  })
